import React from 'react';
import { LiteAuthRoute } from '@xsats-m/controller/dist';

const LiteAppAuthRoute = ({
  component: Component,
  layout: Layout,
  ...rest
}) => {

  Layout = (Layout === undefined) ? props => (<>{props.children}</>) : Layout;

  return (
    <Layout>
      <LiteAuthRoute
        component={Component}
        {...rest}
      />
    </Layout>
  );
}

export default LiteAppAuthRoute;