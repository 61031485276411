import * as React from "react";
import { RegisterStrippedController } from "@xsats-m/controller";
import { RouteComponentProps } from "react-router-dom";
import { StrippedRegisterView } from "./view/StrippedRegisterView";

import * as user from "../../action/user";

export class StrippedRegisterConnector extends React.PureComponent<RouteComponentProps> {
  onFinish = () => {
    user.setReferrerXtag("");
    user.setAcceptTerms(false);

    this.props.history.push("/m/confirm-email", {
      message: "Please confirm your email address",
    });
  };

  render() {
    return (
      <RegisterStrippedController>
        {({ submit }) => (
          <StrippedRegisterView
            onFinish={this.onFinish}
            submit={submit}
            location={this.props.location}
            history={this.props.history}
            match={this.props.match}
          />
        )}
      </RegisterStrippedController>
    );
  }
}
