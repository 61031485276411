import * as React from "react";
import { Form as AntForm, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Field, Form, FormikProps, Formik, FormikHelpers } from "formik";

import { validSignatureSchema } from "@xsats-m/common";
import {
  NormalisedErrorMap,
  WithVerifyAddressSig,
  withVerifyAddressSig,
} from "@xsats-m/controller";
import { InputField } from "../../../shared/InputField";
import store from "../../../../stores/store";
import { ADDRESS_SIG_DECLARATION } from "../../../shared/constants";
import * as addrVer from "../../../../action/addrVer";

import ProgressBarView from "../ProgressBarView";

const FormItem = AntForm.Item;

interface FormValues {
  account: string;
  address: string;
  sig: string;
  sig_id: string;
}

// either get passed back formik error | null
interface Props {
  nextStep: () => void;
  prevStep: () => void;
  goToStep: (step: number) => void;
  handleChange: (input: any) => (e: { target: { value: any } }) => void;
  values: { account: string; address: string; sig: string };
  submit: (values: FormValues) => Promise<NormalisedErrorMap | null>;
  step: number;
}

// Formik takes FormValues then creates some types which are passed into the props => can do * below with nice autocompletion
// The '&' merges the two types/interfaces
class C extends React.PureComponent<
  Props & FormikProps<FormValues> & WithVerifyAddressSig
> {
  continue = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e: { preventDefault: () => void }) => {
    addrVer.clearSigStore();
    e.preventDefault();
    this.props.prevStep();
  };

  submit = async (
    values: FormValues,
    { setSubmitting, setErrors }: FormikHelpers<FormValues>
  ) => {
    // addAddress() is function exported from AddAddress in Controller
    try {
      let response = await this.props.verifyAddressSig(values);

      if (!response) {
        setSubmitting(false);
        console.log("SIGN SUCCESS");
        this.props.nextStep();
        return;
      }

      setErrors(response);

      if (response.address) {
        setErrors({ sig: response.address });
        setTimeout(() => this.props.goToStep(2), 2000);
      } else if (response.account) {
        setErrors({ sig: response.account });
        setTimeout(() => this.props.goToStep(1), 5000);
      } else if (response.sig) {
        setErrors({ sig: response.sig });
      }
    } catch (err) {
      setErrors({ sig: JSON.stringify(err, null, 2) });
    }
    setSubmitting(false);
  };

  render() {
    // custom message to sign
    let msgToSign =
      ADDRESS_SIG_DECLARATION +
      ` (ACCOUNT: ${store.addrVer.account}; SIGNATURE ID: ${store.addrVer.sigId})`;

    const { step } = this.props;

    // valid sig for defaults: H4BNp717BtU6Kmfs0GLj0fiNQ/T54at3SaeUvAw2M/YJSq7/N/T+NvlGm2QIxbo7Abd3VxkGnd9ZPbdKxdKpLBA=
    return (
      <Formik
        initialValues={{
          address: store.addrVer.address,
          account: store.addrVer.account,
          sig: store.addrVer.sig,
          sig_id: store.addrVer.sigId,
        }}
        validationSchema={validSignatureSchema}
        onSubmit={this.submit}
      >
        {({ isSubmitting, isValid }) => (
          <Form style={{ display: "flex", marginTop: 100 }}>
            <div className="Form" style={{ width: 800, margin: "auto" }}>
              <ProgressBarView step={step} />
              <h1 className="Header">
                <strong>Enter signature</strong>.
              </h1>
              <h1 className="Subtext">
                Sign the following message using your wallet, to verify that you
                own your intended receiving address:
              </h1>
              <h2
                style={{
                  fontSize: 14,
                  fontFamily: "ubuntu",
                  fontWeight: 900,
                  display: "flex",
                  verticalAlign: "top",
                  paddingBottom: 20,
                }}
              >
                <div>
                  {msgToSign}
                  <br />
                </div>
                <div>
                  <Button
                    className="BackButton"
                    color="secondary"
                    onClick={() => {
                      navigator.clipboard.writeText(msgToSign);
                    }}
                  >
                    copy
                  </Button>
                </div>
              </h2>
              <Field
                name="sig"
                prefix={
                  (
                    <UserOutlined type="user" style={{ color: "#FF5E00" }} />
                  ) as any
                }
                placeholder="signature..."
                // onChange={handleChange("sig")}
                defaultValue={store.addrVer.sig}
                component={InputField}
              />
              <FormItem>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="FormButton"
                  disabled={isSubmitting}
                  // onClick={() => {
                  //   setFieldValue("account", this.props.values.account)
                  //   this.submit(this.props.values)
                  // }}
                >
                  SUBMIT
                </Button>
              </FormItem>
              <Button
                className="BackButton"
                color="secondary"
                onClick={this.back}
              >
                Back
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export const SigInputView = withVerifyAddressSig(C);
