import * as React from "react";
import { Form as AntForm, Button } from "antd";
import { withFormik, FormikProps, Field, Form } from "formik";
import { InputField } from "../../shared/InputField";
import { NormalisedErrorMap } from "@xsats-m/controller";
import { changePasswordSchema } from "@xsats-m/common";
import { LockOutlined } from "@ant-design/icons";

const FormItem = AntForm.Item;

interface FormValues {
  newPassword: string;
}

// either get passed back formik error | null
interface Props {
  onFinish: () => void;
  submit: (values: FormValues) => Promise<NormalisedErrorMap | null>;
}

// Formik takes FormValues then creates some types which are passed into the props => can do * below with nice autocompletion
// The '&' merges the two types/interfaces
class C extends React.PureComponent<FormikProps<FormValues> & Props> {
  render() {
    return (
      <Form style={{ display: "flex", marginTop: 100 }}>
        <div className="FormEntry" style={{ width: 500, margin: "auto" }}>
          <h1 style={{ fontSize: 28, fontFamily: "ubuntu" }}>
            <strong>Change Password.</strong>
          </h1>
          <h3
            className="Subtext"
            style={{ marginBottom: 10, fontSize: 16, color: "#000" }}
          >
            Choose and confirm your new password.
          </h3>
          <Field
            name="newPassword"
            type="password"
            prefix={
              (
                <LockOutlined
                  type="lock"
                  style={{ color: "rgba(0,0,0,.25)" }}
                />
              ) as any
            }
            placeholder="New password"
            component={InputField}
          />
          <Field
            name="confirmNewPassword"
            type="password"
            prefix={
              (
                <LockOutlined
                  type="lock"
                  style={{ color: "rgba(0,0,0,.25)" }}
                />
              ) as any
            }
            placeholder="Confirm new password"
            component={InputField}
          />
          <FormItem>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{
                width: "100%",
                backgroundColor: "#FF5E00",
                borderWidth: 0,
              }}
            >
              Confirm Password Change
            </Button>
          </FormItem>
        </div>
      </Form>
    );
  }
}

export const ChangePasswordView = withFormik<Props, FormValues>({
  validationSchema: changePasswordSchema,
  mapPropsToValues: () => ({
    newPassword: "",
  }),
  handleSubmit: async (values, { props, setErrors }) => {
    const errors = await props.submit(values);
    if (errors) {
      setErrors(errors);
    } else {
      props.onFinish();
    }
  },
})(C);
