
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faArrowDown, faArrowUp, faEdit, faEllipsisH, faExternalLinkAlt, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Nav, Card, Image, Button, Table, Dropdown, ProgressBar, Pagination, ButtonGroup } from '@themesberg/react-bootstrap';
import { useQuery } from "react-apollo"
import { fetchOrdersQuery } from "@xsats-m/controller";
import Swal from "sweetalert2";

import { pageVisits, pageTraffic, pageRanking } from "../../data-dev/tables";
import commands from "../../data-dev/commands";
import { formatNumber, convertMoneyStringToFloat, replaceMiddle, momentConvertTime } from "@xsats-m/common";
import Preloader from "./Preloader";
import { saveOrders, saveOrderTotals, saveFirstOrderDate } from "../../action/orders";
import store from "../../stores/store";
import { observer } from "mobx-react-lite";

import { getCurrencySymbolFromCode } from "../../modules/shared/utils"


const ValueChange = ({ value, suffix }) => {
  const valueIcon = value < 0 ? faAngleDown : faAngleUp;
  const valueTxtColor = value < 0 ? "text-danger" : "text-success";

  return (
    value ? <span className={valueTxtColor}>
      <FontAwesomeIcon icon={valueIcon} />
      <span className="fw-bold ms-1">
        {Math.abs(value)}{suffix}
      </span>
    </span> : "--"
  );
};

export const PageVisitsTable = () => {
  const TableRow = (props) => {
    const { pageName, views, returnValue, bounceRate } = props;
    const bounceIcon = bounceRate < 0 ? faArrowDown : faArrowUp;
    const bounceTxtColor = bounceRate < 0 ? "text-danger" : "text-success";

    return (
      <tr>
        <th scope="row">{pageName}</th>
        <td>{views}</td>
        <td>${returnValue}</td>
        <td>
          <FontAwesomeIcon icon={bounceIcon} className={`${bounceTxtColor} me-3`} />
          {Math.abs(bounceRate)}%
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <h5>Page visits</h5>
          </Col>
          <Col className="text-end">
            <Button variant="secondary" size="sm">See all</Button>
          </Col>
        </Row>
      </Card.Header>
      <Table responsive className="align-items-center table-flush">
        <thead className="thead-light">
          <tr>
            <th scope="col">Page name</th>
            <th scope="col">Page Views</th>
            <th scope="col">Page Value</th>
            <th scope="col">Bounce rate</th>
          </tr>
        </thead>
        <tbody>
          {pageVisits.map(pv => <TableRow key={`page-visit-${pv.id}`} {...pv} />)}
        </tbody>
      </Table>
    </Card>
  );
};

export const PageTrafficTable = () => {
  const TableRow = (props) => {
    const { id, source, sourceIcon, sourceIconColor, sourceType, category, rank, trafficShare, change } = props;

    return (
      <tr>
        <td>
          <Card.Link href="#" className="text-primary fw-bold">{id}</Card.Link>
        </td>
        <td className="fw-bold">
          <FontAwesomeIcon icon={sourceIcon} className={`icon icon-xs text-${sourceIconColor} w-30`} />
          {source}
        </td>
        <td>{sourceType}</td>
        <td>{category ? category : "--"}</td>
        <td>{rank ? rank : "--"}</td>
        <td>
          <Row className="d-flex align-items-center">
            <Col xs={12} xl={2} className="px-0">
              <small className="fw-bold">{trafficShare}%</small>
            </Col>
            <Col xs={12} xl={10} className="px-0 px-xl-1">
              <ProgressBar variant="primary" className="progress-lg mb-0" now={trafficShare} min={0} max={100} />
            </Col>
          </Row>
        </td>
        <td>
          <ValueChange value={change} suffix="%" />
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm mb-4">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">#</th>
              <th className="border-0">Traffic Source</th>
              <th className="border-0">Source Type</th>
              <th className="border-0">Category</th>
              <th className="border-0">Global Rank</th>
              <th className="border-0">Traffic Share</th>
              <th className="border-0">Change</th>
            </tr>
          </thead>
          <tbody>
            {pageTraffic.map(pt => <TableRow key={`page-traffic-${pt.id}`} {...pt} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export const RankingTable = () => {
  const TableRow = (props) => {
    const { country, countryImage, overallRank, overallRankChange, travelRank, travelRankChange, widgetsRank, widgetsRankChange } = props;

    return (
      <tr>
        <td className="border-0">
          <Card.Link href="#" className="d-flex align-items-center">
            <Image src={countryImage} className="image-small rounded-circle me-2" />
            <div><span className="h6">{country}</span></div>
          </Card.Link>
        </td>
        <td className="fw-bold border-0">
          {overallRank ? overallRank : "-"}
        </td>
        <td className="border-0">
          <ValueChange value={overallRankChange} />
        </td>
        <td className="fw-bold border-0">
          {travelRank ? travelRank : "-"}
        </td>
        <td className="border-0">
          <ValueChange value={travelRankChange} />
        </td>
        <td className="fw-bold border-0">
          {widgetsRank ? widgetsRank : "-"}
        </td>
        <td className="border-0">
          <ValueChange value={widgetsRankChange} />
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">Country</th>
              <th className="border-0">All</th>
              <th className="border-0">All Change</th>
              <th className="border-0">Travel & Local</th>
              <th className="border-0">Travel & Local Change</th>
              <th className="border-0">Widgets</th>
              <th className="border-0">Widgets Change</th>
            </tr>
          </thead>
          <tbody>
            {pageRanking.map(r => <TableRow key={`ranking-${r.id}`} {...r} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export const OrdersTable = observer((props, ref) => {
  // const { data, error, loading, refetch } = { data: null, error: null, loading: null, refetch: null}
  const { data, error, loading, refetch } = useQuery(
    fetchOrdersQuery, { notifyOnNetworkStatusChange: true });

    React.useImperativeHandle(
      ref,
      () => ({refreshTable() {refetch()}}),
   )
  if (!data || !data.fetchOrders) {
    return <div className="d-flex align-items-center pt-3"><span className="text-white">No orders found.</span></div>;
  }

  if (error) {
    return null;
  }

  if (loading) {
    return <Preloader/>
  }

  const orders = data.fetchOrders;
  const totalOrders = orders.length;

  saveOrderTotals(orders);
  saveFirstOrderDate(orders);

  const TableRow = (props) => {
    const { 
      id, created, order_size, currency, bitcoin_withdrawal_amount_sats, bitcoin_network_fee_sats, 
      referral_discount_sats, bitcoin_order_execution_price, status, sub_status, 
      base_currency,  base_currency_exchange_rate, settled_time, completed , payment_surcharge, withdrawal //, withdrawal
    } = props;

    let parsedStatus;
    if (status.trim() === "withdrawal-submitted") {
      parsedStatus = "Complete"
    } else if( status.trim() === "withdrawal-pending") {
      parsedStatus = "Withdrawal Pending"
    } else {
      parsedStatus = "Processing"
    }

    const statusVariant = parsedStatus === "Complete" ? "success"
      : parsedStatus === "Processing" ? "warning"
        : parsedStatus === "Canceled" ? "danger" : "primary";

    // const feeSpreadSats = (((order_size / bitcoin_order_execution_price) * 10**8 ) - bitcoin_withdrawal_amount_sats);
    const costOfGoods = ((bitcoin_withdrawal_amount_sats/ 10 ** 8) * convertMoneyStringToFloat(bitcoin_order_execution_price));
    const feeSpreadFiat = (convertMoneyStringToFloat(order_size) - convertMoneyStringToFloat(costOfGoods)).toFixed(2);

    let idCompact = replaceMiddle(id, 30);

    return (
      <tr>
          <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
              </Dropdown.Item>
              <Dropdown.Item>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item className="text-danger">
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
        <td>
          <Card.Link  onClick={() => {
            navigator.clipboard.writeText(id);
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: `Copied Order ID`,
              text: id,
              showConfirmButton: false,
              timer: 1000,
              backdrop: false,
            });}} className="fw-normal">
            {idCompact}
          </Card.Link>
        </td>
        <td>
          <span className="fw-normal">
          {getCurrencySymbolFromCode(currency)}{convertMoneyStringToFloat(order_size)}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {formatNumber(bitcoin_withdrawal_amount_sats)}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {getCurrencySymbolFromCode(currency)}{formatNumber(convertMoneyStringToFloat(bitcoin_order_execution_price))}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {parsedStatus}
          </span>
          {/* {sub_status !== 0 ? 
            <span className={`fw-normal text-${statusVariant}`}>
              <br/>
              {sub_status}
            </span>: null} */}
        </td>
        <td>
          <span className="fw-normal">
            {momentConvertTime(created).slice(0, 9)}
            <br/>
            {momentConvertTime(created).slice(15)}
          </span>
        </td>
        {/* <td>
          <span className="fw-normal">
            {dueDate}
          </span>
        </td> */}
        <td>
        <div>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-start">
            <Dropdown as={ButtonGroup} style={{ marginRight: "60"}} >
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0" >
              <span className="fw-normal">
            {getCurrencySymbolFromCode(currency)}{formatNumber(convertMoneyStringToFloat(feeSpreadFiat))}
          </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-s dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">Total: {getCurrencySymbolFromCode(currency)}{formatNumber(convertMoneyStringToFloat(feeSpreadFiat))} </Dropdown.Item>
                {payment_surcharge ? <Dropdown.Item className="d-flex fw-bold">Surcharge: + {getCurrencySymbolFromCode(currency)}{convertMoneyStringToFloat(payment_surcharge)}</Dropdown.Item> : null}
                {bitcoin_network_fee_sats ? <Dropdown.Item className="fw-bold">Network Fee: {bitcoin_network_fee_sats}</Dropdown.Item> : null}
                {referral_discount_sats ? <Dropdown.Item className="fw-bold">Discount: {referral_discount_sats} sats</Dropdown.Item> : null}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </div>
          {/* <span className="fw-normal">
            {getCurrencySymbolFromCode(currency)}{formatNumber(convertMoneyStringToFloat(feeSpreadFiat))}
          </span>
          {payment_surcharge ? 
            <span className="fw-light">
              <br/>
              +{formatNumber(convertMoneyStringToFloat(payment_surcharge))}
            </span> : null} */}
        </td>
        <td>
          <span className="fw-normal">
            {withdrawal ? withdrawal.address : "..."}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {formatNumber(referral_discount_sats)}
          </span>
        </td>      
      </tr>
    );
  };

  // Description -  e.g. Onchain withdrawal/LN withdrawal/purchase to xsats wallet
  // Processed - broadcast timestamp
  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">Action</th>
              <th className="border-bottom">Order ID</th>
              <th className="border-bottom">Payment</th>
              <th className="border-bottom">Sats</th>
              <th className="border-bottom">Price</th>
              <th className="border-bottom">Status</th>
              <th className="border-bottom">Processed</th>
              <th className="border-bottom">Fee</th>
              <th className="border-bottom">Address</th>
              <th className="border-bottom">Referral Bonus</th>
            </tr>
          </thead>
          <tbody>
            {orders.map(o => <TableRow key={`order-${o.id}`} {...o} />)}
          </tbody>
        </Table>
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
            <Pagination className="mb-2 mb-lg-0">
              <Pagination.Prev>
                Previous
              </Pagination.Prev>
              <Pagination.Item active>1</Pagination.Item>
              {/* <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>4</Pagination.Item>
              <Pagination.Item>5</Pagination.Item> */}
              <Pagination.Next>
                Next
              </Pagination.Next>
            </Pagination>
          </Nav>
          <small className="fw-bold">
            Showing <b>{totalOrders}</b> out of <b>{totalOrders}</b> entries
          </small>
        </Card.Footer>
      </Card.Body>
    </Card>
  );
}, { forwardRef: true});

export const CommandsTable = () => {
  const TableRow = (props) => {
    const { name, usage = [], description, link } = props;

    return (
      <tr>
        <td className="border-0" style={{ width: '5%' }}>
          <code>{name}</code>
        </td>
        <td className="fw-bold border-0" style={{ width: '5%' }}>
          <ul className="ps-0">
            {usage.map(u => (
              <ol key={u} className="ps-0">
                <code>{u}</code>
              </ol>
            ))}
          </ul>
        </td>
        <td className="border-0" style={{ width: '50%' }}>
          <pre className="m-0 p-0">{description}</pre>
        </td>
        <td className="border-0" style={{ width: '40%' }}>
          <pre><Card.Link href={link} target="_blank">Read More <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1" /></Card.Link></pre>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="p-0">
        <Table responsive className="table-centered rounded" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
          <thead className="thead-light">
            <tr>
              <th className="border-0" style={{ width: '5%' }}>Name</th>
              <th className="border-0" style={{ width: '5%' }}>Usage</th>
              <th className="border-0" style={{ width: '50%' }}>Description</th>
              <th className="border-0" style={{ width: '40%' }}>Extra</th>
            </tr>
          </thead>
          <tbody>
            {commands.map(c => <TableRow key={`command-${c.id}`} {...c} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};