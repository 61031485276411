export const getCurrencySymbolFromCode = (
  currencyCode: "GBP" | "EUR" | "CHF" | "USD"
) => {
  if (!currencyCode) {
    return;
  } else if (currencyCode === "GBP") {
    return "£";
  } else if (currencyCode === "EUR") {
    return "€";
  } else if (currencyCode === "CHF") {
    return "₣";
  } else if (currencyCode === "USD") {
    return "$";
  } else {
    throw new Error("UNSUPPORTED CURRENCY (getSymbolFromCode)");
  }
};
