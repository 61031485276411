import * as React from "react";
import { FieldProps } from "formik";
import { Form, Input, Select } from "antd";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import OtpInput from "react-otp-input";
import "../../index.css";
import "./styles.css";

const FormItem = Form.Item;
const { Option } = Select;

export const InputField: React.SFC<
  FieldProps<any> & {
    prefix: React.ReactNode;
  }
> = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const errorMsg = touched[field.name] && errors[field.name];

  return (
    <FormItem help={errorMsg} validateStatus={errorMsg ? "error" : undefined}>
      <Input {...field} {...props} />
    </FormItem>
  );
};

export const OTPInputField: React.SFC<
  FieldProps<any> & {
    prefix: React.ReactNode;
  }
> = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const errorMsg = touched[field.name] && errors[field.name];

  return (
    <FormItem help={errorMsg} validateStatus={errorMsg ? "error" : undefined}>
      <OtpInput
        className="otp-input"
        // value={field.value}
        // onChange={handleChange}
        numInputs={6}
        separator={<span>-</span>}
        inputStyle={{
          width: 40,
          borderRadius: 5,
          shadow: false,
          height: 50,
          fontColor: "#000",
        }}
        {...field}
        {...props}
      />
    </FormItem>
  );
};

export const CountryRegionDropdown: React.SFC<
  FieldProps<any> & {
    prefix: React.ReactNode;
  }
> = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const errorMsg = touched[field.name] && errors[field.name];

  return (
    <FormItem
      className="InputContainer"
      help={errorMsg}
      validateStatus={errorMsg ? "error" : undefined}
    >
      <CountryDropdown {...field} {...props} />
    </FormItem>
  );
};

interface Props {
  country: string;
}

export const FormikRegionDropdown: React.SFC<
  FieldProps<any> &
    Props & {
      prefix: React.ReactNode;
    }
> = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const errorMsg = touched[field.name] && errors[field.name];

  return (
    <FormItem
      className="InputContainer"
      help={errorMsg}
      validateStatus={errorMsg ? "error" : undefined}
    >
      <RegionDropdown {...field} {...props} />
    </FormItem>
  );
};

export const DropdownInput: React.SFC<
  FieldProps<any> & {
    prefix: React.ReactNode;
  }
> = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const errorMsg = touched[field.name] && errors[field.name];

  return (
    <FormItem help={errorMsg} validateStatus={errorMsg ? "error" : undefined}>
      {/* <Input {...field} {...props} /> */}
      <Select {...field} {...props}>
        <Option value="china">China</Option>
        <Option value="usa">U.S.A</Option>
      </Select>
    </FormItem>
  );
};
