import React from "react";
import FooterRaw from "../../modules/shared/Footer/FooterRaw";
import Header from "./Header";
import { observer } from "mobx-react-lite";

const LayoutAuth = ({ children }) => (
  <>
    <Header navPosition="right" className="reveal-from-bottom" hideSignin={true} showLogout={true} />
    {children}
    <FooterRaw />
  </>
);

export default observer(LayoutAuth);
