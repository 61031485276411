// const currencies = ["£", "€", "$", " sats", "₿"];
export const currenciesDict = {
  CHF: {
    symbol: "₣",
  },
  GBP: {
    symbol: "£",
  },
  EUR: {
    symbol: "€",
  },
  USD: {
    symbol: "$",
  },
};

export const currencies = [
  {
    label: "€",
    value: "EUR",
  },
  {
    label: "₣",
    value: "CHF",
  },
  {
    label: "£",
    value: "GBP",
  },
  {
    label: "$",
    value: "USD",
  },
];

export const currencies1 = [
  {
    label: "€",
    value: "EUR",
  },
  {
    label: "₣",
    value: "CHF",
  },
];

export const currencies2 = [
  {
    label: "£",
    value: "GBP",
  },
  {
    label: "$",
    value: "USD",
  },
];

export const currencyPairs = ["XBT/GBP", "XBT/EUR", "XBT/USD", "XBT/CHF"];

export const ADDRESS_SIG_DECLARATION =
  "To purchase bitcoin doc-free via xsats, we are required to verify your ownership of the receiving address. By signing this message using your wallet, you confirm that a) you are the sole owner of the address b) in completing this purchase you will send funds from your own account";
// List of all countries in a simple list / array.
// Sorted alphabetical by country value (special characters on bottom)
export const countryList = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo (the Democratic Republic of the)",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands [Malvinas]",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (the Democratic People's Republic of)",
  "Korea (the Republic of)",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova (the Republic of)",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States Minor Outlying Islands",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic of)",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Åland Islands",
];

export const europeanCountriesIsoData = [
  "BE",
  "BG",
  "CZ",
  "DK",
  "DE",
  "EE",
  "IE",
  "EL",
  "ES",
  "FR",
  "HR",
  "IT",
  "CY",
  "LV",
  "LT",
  "LU",
  "HU",
  "MT",
  "NL",
  "AT",
  "PL",
  "PT",
  "RO",
  "SI",
  "SK",
  "FI",
  "SE",
  "GB",
];

export const countryListAllIsoData = [
  { code: "AF", code3: "AFG", value: "Afghanistan", number: "004" },
  { code: "AL", code3: "ALB", value: "Albania", number: "008" },
  { code: "DZ", code3: "DZA", value: "Algeria", number: "012" },
  { code: "AS", code3: "ASM", value: "American Samoa", number: "016" },
  { code: "AD", code3: "AND", value: "Andorra", number: "020" },
  { code: "AO", code3: "AGO", value: "Angola", number: "024" },
  { code: "AI", code3: "AIA", value: "Anguilla", number: "660" },
  { code: "AQ", code3: "ATA", value: "Antarctica", number: "010" },
  { code: "AG", code3: "ATG", value: "Antigua and Barbuda", number: "028" },
  { code: "AR", code3: "ARG", value: "Argentina", number: "032" },
  { code: "AM", code3: "ARM", value: "Armenia", number: "051" },
  { code: "AW", code3: "ABW", value: "Aruba", number: "533" },
  { code: "AU", code3: "AUS", value: "Australia", number: "036" },
  { code: "AT", code3: "AUT", value: "Austria", number: "040" },
  { code: "AZ", code3: "AZE", value: "Azerbaijan", number: "031" },
  { code: "BS", code3: "BHS", value: "Bahamas", number: "044" },
  { code: "BH", code3: "BHR", value: "Bahrain", number: "048" },
  { code: "BD", code3: "BGD", value: "Bangladesh", number: "050" },
  { code: "BB", code3: "BRB", value: "Barbados", number: "052" },
  { code: "BY", code3: "BLR", value: "Belarus", number: "112" },
  { code: "BE", code3: "BEL", value: "Belgium", number: "056" },
  { code: "BZ", code3: "BLZ", value: "Belize", number: "084" },
  { code: "BJ", code3: "BEN", value: "Benin", number: "204" },
  { code: "BM", code3: "BMU", value: "Bermuda", number: "060" },
  { code: "BT", code3: "BTN", value: "Bhutan", number: "064" },
  {
    code: "BO",
    code3: "BOL",
    value: "Bolivia (Plurinational State of)",
    number: "068",
  },
  {
    code: "BQ",
    code3: "BES",
    value: "Bonaire, Sint Eustatius and Saba",
    number: "535",
  },
  { code: "BA", code3: "BIH", value: "Bosnia and Herzegovina", number: "070" },
  { code: "BW", code3: "BWA", value: "Botswana", number: "072" },
  { code: "BV", code3: "BVT", value: "Bouvet Island", number: "074" },
  { code: "BR", code3: "BRA", value: "Brazil", number: "076" },
  {
    code: "IO",
    code3: "IOT",
    value: "British Indian Ocean Territory",
    number: "086",
  },
  { code: "BN", code3: "BRN", value: "Brunei Darussalam", number: "096" },
  { code: "BG", code3: "BGR", value: "Bulgaria", number: "100" },
  { code: "BF", code3: "BFA", value: "Burkina Faso", number: "854" },
  { code: "BI", code3: "BDI", value: "Burundi", number: "108" },
  { code: "CV", code3: "CPV", value: "Cabo Verde", number: "132" },
  { code: "KH", code3: "KHM", value: "Cambodia", number: "116" },
  { code: "CM", code3: "CMR", value: "Cameroon", number: "120" },
  { code: "CA", code3: "CAN", value: "Canada", number: "124" },
  { code: "KY", code3: "CYM", value: "Cayman Islands", number: "136" },
  {
    code: "CF",
    code3: "CAF",
    value: "Central African Republic",
    number: "140",
  },
  { code: "TD", code3: "TCD", value: "Chad", number: "148" },
  { code: "CL", code3: "CHL", value: "Chile", number: "152" },
  { code: "CN", code3: "CHN", value: "China", number: "156" },
  { code: "CX", code3: "CXR", value: "Christmas Island", number: "162" },
  {
    code: "CC",
    code3: "CCK",
    value: "Cocos (Keeling) Islands",
    number: "166",
  },
  { code: "CO", code3: "COL", value: "Colombia", number: "170" },
  { code: "KM", code3: "COM", value: "Comoros", number: "174" },
  {
    code: "CD",
    code3: "COD",
    value: "Congo (the Democratic Republic of the)",
    number: "180",
  },
  { code: "CG", code3: "COG", value: "Congo", number: "178" },
  { code: "CK", code3: "COK", value: "Cook Islands", number: "184" },
  { code: "CR", code3: "CRI", value: "Costa Rica", number: "188" },
  { code: "HR", code3: "HRV", value: "Croatia", number: "191" },
  { code: "CU", code3: "CUB", value: "Cuba", number: "192" },
  { code: "CW", code3: "CUW", value: "Curaçao", number: "531" },
  { code: "CY", code3: "CYP", value: "Cyprus", number: "196" },
  { code: "CZ", code3: "CZE", value: "Czechia", number: "203" },
  { code: "CI", code3: "CIV", value: "Côte d'Ivoire", number: "384" },
  { code: "DK", code3: "DNK", value: "Denmark", number: "208" },
  { code: "DJ", code3: "DJI", value: "Djibouti", number: "262" },
  { code: "DM", code3: "DMA", value: "Dominica", number: "212" },
  {
    code: "DO",
    code3: "DOM",
    value: "Dominican Republic",
    number: "214",
  },
  { code: "EC", code3: "ECU", value: "Ecuador", number: "218" },
  { code: "EG", code3: "EGY", value: "Egypt", number: "818" },
  { code: "SV", code3: "SLV", value: "El Salvador", number: "222" },
  { code: "GQ", code3: "GNQ", value: "Equatorial Guinea", number: "226" },
  { code: "ER", code3: "ERI", value: "Eritrea", number: "232" },
  { code: "EE", code3: "EST", value: "Estonia", number: "233" },
  { code: "SZ", code3: "SWZ", value: "Eswatini", number: "748" },
  { code: "ET", code3: "ETH", value: "Ethiopia", number: "231" },
  {
    code: "FK",
    code3: "FLK",
    value: "Falkland Islands [Malvinas]",
    number: "238",
  },
  { code: "FO", code3: "FRO", value: "Faroe Islands", number: "234" },
  { code: "FJ", code3: "FJI", value: "Fiji", number: "242" },
  { code: "FI", code3: "FIN", value: "Finland", number: "246" },
  { code: "FR", code3: "FRA", value: "France", number: "250" },
  { code: "GF", code3: "GUF", value: "French Guiana", number: "254" },
  { code: "PF", code3: "PYF", value: "French Polynesia", number: "258" },
  {
    code: "TF",
    code3: "ATF",
    value: "French Southern Territories",
    number: "260",
  },
  { code: "GA", code3: "GAB", value: "Gabon", number: "266" },
  { code: "GM", code3: "GMB", value: "Gambia", number: "270" },
  { code: "GE", code3: "GEO", value: "Georgia", number: "268" },
  { code: "DE", code3: "DEU", value: "Germany", number: "276" },
  { code: "GH", code3: "GHA", value: "Ghana", number: "288" },
  { code: "GI", code3: "GIB", value: "Gibraltar", number: "292" },
  { code: "GR", code3: "GRC", value: "Greece", number: "300" },
  { code: "GL", code3: "GRL", value: "Greenland", number: "304" },
  { code: "GD", code3: "GRD", value: "Grenada", number: "308" },
  { code: "GP", code3: "GLP", value: "Guadeloupe", number: "312" },
  { code: "GU", code3: "GUM", value: "Guam", number: "316" },
  { code: "GT", code3: "GTM", value: "Guatemala", number: "320" },
  { code: "GG", code3: "GGY", value: "Guernsey", number: "831" },
  { code: "GN", code3: "GIN", value: "Guinea", number: "324" },
  { code: "GW", code3: "GNB", value: "Guinea-Bissau", number: "624" },
  { code: "GY", code3: "GUY", value: "Guyana", number: "328" },
  { code: "HT", code3: "HTI", value: "Haiti", number: "332" },
  {
    code: "HM",
    code3: "HMD",
    value: "Heard Island and McDonald Islands",
    number: "334",
  },
  { code: "VA", code3: "VAT", value: "Holy See", number: "336" },
  { code: "HN", code3: "HND", value: "Honduras", number: "340" },
  { code: "HK", code3: "HKG", value: "Hong Kong", number: "344" },
  { code: "HU", code3: "HUN", value: "Hungary", number: "348" },
  { code: "IS", code3: "ISL", value: "Iceland", number: "352" },
  { code: "IN", code3: "IND", value: "India", number: "356" },
  { code: "ID", code3: "IDN", value: "Indonesia", number: "360" },
  {
    code: "IR",
    code3: "IRN",
    value: "Iran (Islamic Republic of)",
    number: "364",
  },
  { code: "IQ", code3: "IRQ", value: "Iraq", number: "368" },
  { code: "IE", code3: "IRL", value: "Ireland", number: "372" },
  { code: "IM", code3: "IMN", value: "Isle of Man", number: "833" },
  { code: "IL", code3: "ISR", value: "Israel", number: "376" },
  { code: "IT", code3: "ITA", value: "Italy", number: "380" },
  { code: "JM", code3: "JAM", value: "Jamaica", number: "388" },
  { code: "JP", code3: "JPN", value: "Japan", number: "392" },
  { code: "JE", code3: "JEY", value: "Jersey", number: "832" },
  { code: "JO", code3: "JOR", value: "Jordan", number: "400" },
  { code: "KZ", code3: "KAZ", value: "Kazakhstan", number: "398" },
  { code: "KE", code3: "KEN", value: "Kenya", number: "404" },
  { code: "KI", code3: "KIR", value: "Kiribati", number: "296" },
  {
    code: "KP",
    code3: "PRK",
    value: "Korea (the Democratic People's Republic of)",
    number: "408",
  },
  { code: "KR", code3: "KOR", value: "Korea (the Republic of)", number: "410" },
  { code: "KW", code3: "KWT", value: "Kuwait", number: "414" },
  { code: "KG", code3: "KGZ", value: "Kyrgyzstan", number: "417" },
  {
    code: "LA",
    code3: "LAO",
    value: "Lao People's Democratic Republic",
    number: "418",
  },
  { code: "LV", code3: "LVA", value: "Latvia", number: "428" },
  { code: "LB", code3: "LBN", value: "Lebanon", number: "422" },
  { code: "LS", code3: "LSO", value: "Lesotho", number: "426" },
  { code: "LR", code3: "LBR", value: "Liberia", number: "430" },
  { code: "LY", code3: "LBY", value: "Libya", number: "434" },
  { code: "LI", code3: "LIE", value: "Liechtenstein", number: "438" },
  { code: "LT", code3: "LTU", value: "Lithuania", number: "440" },
  { code: "LU", code3: "LUX", value: "Luxembourg", number: "442" },
  { code: "MO", code3: "MAC", value: "Macao", number: "446" },
  { code: "MG", code3: "MDG", value: "Madagascar", number: "450" },
  { code: "MW", code3: "MWI", value: "Malawi", number: "454" },
  { code: "MY", code3: "MYS", value: "Malaysia", number: "458" },
  { code: "MV", code3: "MDV", value: "Maldives", number: "462" },
  { code: "ML", code3: "MLI", value: "Mali", number: "466" },
  { code: "MT", code3: "MLT", value: "Malta", number: "470" },
  { code: "MH", code3: "MHL", value: "Marshall Islands", number: "584" },
  { code: "MQ", code3: "MTQ", value: "Martinique", number: "474" },
  { code: "MR", code3: "MRT", value: "Mauritania", number: "478" },
  { code: "MU", code3: "MUS", value: "Mauritius", number: "480" },
  { code: "YT", code3: "MYT", value: "Mayotte", number: "175" },
  { code: "MX", code3: "MEX", value: "Mexico", number: "484" },
  {
    code: "FM",
    code3: "FSM",
    value: "Micronesia (Federated States of)",
    number: "583",
  },
  {
    code: "MD",
    code3: "MDA",
    value: "Moldova (the Republic of)",
    number: "498",
  },
  { code: "MC", code3: "MCO", value: "Monaco", number: "492" },
  { code: "MN", code3: "MNG", value: "Mongolia", number: "496" },
  { code: "ME", code3: "MNE", value: "Montenegro", number: "499" },
  { code: "MS", code3: "MSR", value: "Montserrat", number: "500" },
  { code: "MA", code3: "MAR", value: "Morocco", number: "504" },
  { code: "MZ", code3: "MOZ", value: "Mozambique", number: "508" },
  { code: "MM", code3: "MMR", value: "Myanmar", number: "104" },
  { code: "NA", code3: "NAM", value: "Namibia", number: "516" },
  { code: "NR", code3: "NRU", value: "Nauru", number: "520" },
  { code: "NP", code3: "NPL", value: "Nepal", number: "524" },
  { code: "NL", code3: "NLD", value: "Netherlands", number: "528" },
  { code: "NC", code3: "NCL", value: "New Caledonia", number: "540" },
  { code: "NZ", code3: "NZL", value: "New Zealand", number: "554" },
  { code: "NI", code3: "NIC", value: "Nicaragua", number: "558" },
  { code: "NE", code3: "NER", value: "Niger", number: "562" },
  { code: "NG", code3: "NGA", value: "Nigeria", number: "566" },
  { code: "NU", code3: "NIU", value: "Niue", number: "570" },
  { code: "NF", code3: "NFK", value: "Norfolk Island", number: "574" },
  {
    code: "MP",
    code3: "MNP",
    value: "Northern Mariana Islands",
    number: "580",
  },
  { code: "NO", code3: "NOR", value: "Norway", number: "578" },
  { code: "OM", code3: "OMN", value: "Oman", number: "512" },
  { code: "PK", code3: "PAK", value: "Pakistan", number: "586" },
  { code: "PW", code3: "PLW", value: "Palau", number: "585" },
  { code: "PS", code3: "PSE", value: "Palestine, State of", number: "275" },
  { code: "PA", code3: "PAN", value: "Panama", number: "591" },
  { code: "PG", code3: "PNG", value: "Papua New Guinea", number: "598" },
  { code: "PY", code3: "PRY", value: "Paraguay", number: "600" },
  { code: "PE", code3: "PER", value: "Peru", number: "604" },
  { code: "PH", code3: "PHL", value: "Philippines", number: "608" },
  { code: "PN", code3: "PCN", value: "Pitcairn", number: "612" },
  { code: "PL", code3: "POL", value: "Poland", number: "616" },
  { code: "PT", code3: "PRT", value: "Portugal", number: "620" },
  { code: "PR", code3: "PRI", value: "Puerto Rico", number: "630" },
  { code: "QA", code3: "QAT", value: "Qatar", number: "634" },
  {
    code: "MK",
    code3: "MKD",
    value: "Republic of North Macedonia",
    number: "807",
  },
  { code: "RO", code3: "ROU", value: "Romania", number: "642" },
  {
    code: "RU",
    code3: "RUS",
    value: "Russian Federation",
    number: "643",
  },
  { code: "RW", code3: "RWA", value: "Rwanda", number: "646" },
  { code: "RE", code3: "REU", value: "Réunion", number: "638" },
  { code: "BL", code3: "BLM", value: "Saint Barthélemy", number: "652" },
  {
    code: "SH",
    code3: "SHN",
    value: "Saint Helena, Ascension and Tristan da Cunha",
    number: "654",
  },
  { code: "KN", code3: "KNA", value: "Saint Kitts and Nevis", number: "659" },
  { code: "LC", code3: "LCA", value: "Saint Lucia", number: "662" },
  {
    code: "MF",
    code3: "MAF",
    value: "Saint Martin (French part)",
    number: "663",
  },
  {
    code: "PM",
    code3: "SPM",
    value: "Saint Pierre and Miquelon",
    number: "666",
  },
  {
    code: "VC",
    code3: "VCT",
    value: "Saint Vincent and the Grenadines",
    number: "670",
  },
  { code: "WS", code3: "WSM", value: "Samoa", number: "882" },
  { code: "SM", code3: "SMR", value: "San Marino", number: "674" },
  { code: "ST", code3: "STP", value: "Sao Tome and Principe", number: "678" },
  { code: "SA", code3: "SAU", value: "Saudi Arabia", number: "682" },
  { code: "SN", code3: "SEN", value: "Senegal", number: "686" },
  { code: "RS", code3: "SRB", value: "Serbia", number: "688" },
  { code: "SC", code3: "SYC", value: "Seychelles", number: "690" },
  { code: "SL", code3: "SLE", value: "Sierra Leone", number: "694" },
  { code: "SG", code3: "SGP", value: "Singapore", number: "702" },
  {
    code: "SX",
    code3: "SXM",
    value: "Sint Maarten (Dutch part)",
    number: "534",
  },
  { code: "SK", code3: "SVK", value: "Slovakia", number: "703" },
  { code: "SI", code3: "SVN", value: "Slovenia", number: "705" },
  { code: "SB", code3: "SLB", value: "Solomon Islands", number: "090" },
  { code: "SO", code3: "SOM", value: "Somalia", number: "706" },
  { code: "ZA", code3: "ZAF", value: "South Africa", number: "710" },
  {
    code: "GS",
    code3: "SGS",
    value: "South Georgia and the South Sandwich Islands",
    number: "239",
  },
  { code: "SS", code3: "SSD", value: "South Sudan", number: "728" },
  { code: "ES", code3: "ESP", value: "Spain", number: "724" },
  { code: "LK", code3: "LKA", value: "Sri Lanka", number: "144" },
  { code: "SD", code3: "SDN", value: "Sudan", number: "729" },
  { code: "SR", code3: "SUR", value: "Suriname", number: "740" },
  { code: "SJ", code3: "SJM", value: "Svalbard and Jan Mayen", number: "744" },
  { code: "SE", code3: "SWE", value: "Sweden", number: "752" },
  { code: "CH", code3: "CHE", value: "Switzerland", number: "756" },
  { code: "SY", code3: "SYR", value: "Syrian Arab Republic", number: "760" },
  { code: "TW", code3: "TWN", value: "Taiwan", number: "158" },
  { code: "TJ", code3: "TJK", value: "Tajikistan", number: "762" },
  {
    code: "TZ",
    code3: "TZA",
    value: "Tanzania, United Republic of",
    number: "834",
  },
  { code: "TH", code3: "THA", value: "Thailand", number: "764" },
  { code: "TL", code3: "TLS", value: "Timor-Leste", number: "626" },
  { code: "TG", code3: "TGO", value: "Togo", number: "768" },
  { code: "TK", code3: "TKL", value: "Tokelau", number: "772" },
  { code: "TO", code3: "TON", value: "Tonga", number: "776" },
  { code: "TT", code3: "TTO", value: "Trinidad and Tobago", number: "780" },
  { code: "TN", code3: "TUN", value: "Tunisia", number: "788" },
  { code: "TR", code3: "TUR", value: "Turkey", number: "792" },
  { code: "TM", code3: "TKM", value: "Turkmenistan", number: "795" },
  {
    code: "TC",
    code3: "TCA",
    value: "Turks and Caicos Islands",
    number: "796",
  },
  { code: "TV", code3: "TUV", value: "Tuvalu", number: "798" },
  { code: "UG", code3: "UGA", value: "Uganda", number: "800" },
  { code: "UA", code3: "UKR", value: "Ukraine", number: "804" },
  {
    code: "AE",
    code3: "ARE",
    value: "United Arab Emirates",
    number: "784",
  },
  {
    code: "GB",
    code3: "GBR",
    value: "United Kingdom",
    number: "826",
  },
  {
    code: "UM",
    code3: "UMI",
    value: "United States Minor Outlying Islands",
    number: "581",
  },
  {
    code: "US",
    code3: "USA",
    value: "United States of America",
    number: "840",
  },
  { code: "UY", code3: "URY", value: "Uruguay", number: "858" },
  { code: "UZ", code3: "UZB", value: "Uzbekistan", number: "860" },
  { code: "VU", code3: "VUT", value: "Vanuatu", number: "548" },
  {
    code: "VE",
    code3: "VEN",
    value: "Venezuela (Bolivarian Republic of)",
    number: "862",
  },
  { code: "VN", code3: "VNM", value: "Viet Nam", number: "704" },
  {
    code: "VG",
    code3: "VGB",
    value: "Virgin Islands (British)",
    number: "092",
  },
  { code: "VI", code3: "VIR", value: "Virgin Islands (U.S.)", number: "850" },
  { code: "WF", code3: "WLF", value: "Wallis and Futuna", number: "876" },
  { code: "EH", code3: "ESH", value: "Western Sahara", number: "732" },
  { code: "YE", code3: "YEM", value: "Yemen", number: "887" },
  { code: "ZM", code3: "ZMB", value: "Zambia", number: "894" },
  { code: "ZW", code3: "ZWE", value: "Zimbabwe", number: "716" },
  { code: "AX", code3: "ALA", value: "Åland Islands", number: "248" },
];
