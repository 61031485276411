import * as React from "react";
import { ChangePasswordView } from "./view/ChangePasswordView";
import { RouteComponentProps } from "react-router-dom";
import { ChangePasswordController } from "@xsats-m/controller";

export class ChangePasswordConnector extends React.PureComponent<
  RouteComponentProps<{
    key: string;
  }>
> {
  submit = async (values: any) => {
    console.log(values);
    return null;
  };

  onFinish = () => {
    this.props.history.push("/start");
  };

  render() {
    console.log(this.props);
    // extract key from props
    const {
      match: {
        params: { key },
      },
    } = this.props;
    console.log(key);
    // lamda function submit
    return (
      <ChangePasswordController>
        {({ submit }) => (
          <ChangePasswordView
            onFinish={this.onFinish}
            submit={async ({ newPassword }) =>
              submit({
                key,
                newPassword,
              })
            }
          />
        )}
      </ChangePasswordController>
    );
  }
}
