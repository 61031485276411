import React from "react";
import { BankDetailsView } from "../../payment/BankDetailsView";
import { ConfirmationView } from "../../payment/ConfirmationView";
// import Confirm from "./Confirm";
// import Success from "./Success";
import { AddressInputView } from "./views/AddressInputView";
import { AOPPSigView } from "./views/AOPPSigView";
import { AccountNumberInputView } from "./views/AccountNumberInputView";
import { SigInputView } from "./views/SigInputView";

class UserForm extends React.PureComponent {
  state = {
    step: 1,
    account: "",
    address: "",
    sig: "",
  };

  // Proceed to next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  // Go back to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: ~~(step - 1),
    });
  };

  // Go to specific step
  goToStep = (step: number) => {
    this.setState({
      step: step,
    });
  };

  // Handle fields change
  handleChange = (input: string) => (e: { target: { value: any } }) => {
    this.setState({ [input]: e.target.value });
    // console.log("SETTING STATE: " + this.state[input]);
  };

  render() {
    const { step } = this.state;
    const { account, address, sig } = this.state;
    const values = { account, address, sig };

    switch (step) {
      case 1:
        return (
          <AccountNumberInputView
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
            step={step}
          />
        );
      case 2:
        return (
          <AddressInputView
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            goToStep={this.goToStep}
            handleChange={this.handleChange}
            values={values}
            step={step}
          />
        );
      case 3:
        return (
          <SigInputView
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            goToStep={this.goToStep}
            values={values}
            handleChange={this.handleChange}
            step={step}
          />
        );
      case 3.1:
        return (
          <AOPPSigView
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            goToStep={this.goToStep}
            values={values}
            step={step}
          />
        );
      // case 3.2:
      //   return (
      //     <BlueWalletSigView
      //       nextStep={this.nextStep}
      //       prevStep={this.prevStep}
      //       values={values}
      //     />
      //   );
      // case 3.3:
      //   return (
      //     <BlueWalletSigView
      //       nextStep={this.nextStep}
      //       prevStep={this.prevStep}
      //       values={values}
      //     />
      //   );
      case 4:
        // show payment details
        // @ts-ignore
        return <BankDetailsView nextStep={this.nextStep} />;
      case 5:
        return (
          <ConfirmationView nextStep={this.nextStep} prevStep={this.prevStep} />
        );
      default:
        console.log("This is a multi-step form built with React.");
        return;
    }
  }
}

export default UserForm;
