import * as React from "react";
import { Form as AntForm, Button } from "antd";
import { withFormik, FormikProps, Field, Form } from "formik";
import { validLoginSchema } from "@xsats-m/common";
import { InputField } from "../../shared/InputField";
import { Link, RouteComponentProps } from "react-router-dom";
import { NormalisedErrorMap } from "@xsats-m/controller";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import OtpInput from "react-otp-input";
import { setEmail } from "../../../action/user";

const FormItem = AntForm.Item;

interface FormValues {
  email: string;
  password: string;
  totp?: string;
}

// either get passed back formik error | null
interface Props {
  onFinish: () => void;
  totpInput: (values: FormValues) => void;
  submit: (values: FormValues) => Promise<NormalisedErrorMap | null>;
}

// Formik takes FormValues then creates some types which are passed into the props => can do * below with nice autocompletion
// The '&' merges the two types/interfaces
class C extends React.PureComponent<
  RouteComponentProps & FormikProps<FormValues> & Props
> {
  state = {
    otp: "",
  };

  handleErrors = (field: string) => {
    const errorMsg = this.props.touched[field] && this.props.errors[field];
    return errorMsg;
  };

  handleChange = (otp: string) => {
    this.setState({ otp });
    this.props.setFieldValue("totp", otp);
  };

  render() {
    return (
      <Form style={{ display: "flex", marginTop: 100 }}>
        <div className="FormEntry" style={{ width: 500, margin: "auto" }}>
          <div>
            <h1 className="Header" style={{ marginBottom: 20 }}>
              <strong>Continue Stacking.</strong>
            </h1>
            {/* <h2>Start saving bitcoin today.</h2> */}
          </div>
          {this.props.status !== true ? (
            <div>
              <Field
                name="email"
                prefix={
                  (
                    <MailOutlined type="mail" style={{ color: "#FF5E00" }} />
                  ) as any
                }
                placeholder="Email"
                component={InputField}
                className="InputField"
              />
              <Field
                name="password"
                type="password"
                prefix={
                  (
                    <LockOutlined type="lock" style={{ color: "#FF5E00" }} />
                  ) as any
                }
                placeholder="Password"
                component={InputField}
              />
              <FormItem style={{ marginBottom: 0, width: "100%" }}>
                <Button type="primary" htmlType="submit" className="FormButton">
                  BUY
                </Button>
              </FormItem>
              <FormItem style={{ fontFamily: "ubuntu" }}>
                <Link className="SecondaryText" to="/forgot-password">
                  Forgot password
                </Link>
              </FormItem>
              <FormItem className="FormText">
                First time?{" "}
                <Link to="/x/" className="FormTextBold">
                  Get started.
                </Link>
              </FormItem>
            </div>
          ) : (
            <div style={{ justifyContent: "center" }}>
              <h2>Enter your 2FA code...</h2>
              <FormItem
                help={this.handleErrors("totp")}
                validateStatus={this.handleErrors("totp") ? "error" : undefined}
                style={{ marginBottom: 80 }}
              >
                <OtpInput
                  className="otp-input"
                  value={this.state.otp}
                  onChange={this.handleChange}
                  numInputs={6}
                  // separator={<span>---</span>}
                  inputStyle={{
                    width: 40,
                    borderRadius: 5,
                    borderWidth: 1,
                    shadow: false,
                    height: 50,
                    fontColor: "#000",
                    marginTop: 10,
                  }}
                  containerStyle={{
                    paddingHorizontal: 20,
                  }}
                  hasErrored={typeof this.props.errors.totp !== "undefined"}
                />
              </FormItem>
              <FormItem style={{ marginBottom: 0, width: "100%" }}>
                <Button type="primary" htmlType="submit" className="FormButton">
                  CONTINUE
                </Button>
              </FormItem>
            </div>
          )}
        </div>
      </Form>
    );
  }
}

export const LoginView = withFormik<RouteComponentProps & Props, FormValues>({
  validationSchema: validLoginSchema,
  mapPropsToValues: () => ({
    email: "",
    password: "",
    totp: "",
  }),
  handleSubmit: async (values, { props, setErrors, setStatus }) => {
    const errors = await props.submit(values);
    if (errors && errors.totp && !errors.email && !errors.password) {
      // direct to totp input
      setStatus(true);
      if (errors.totp !== "Please enter your 2FA code") {
        setErrors(errors);
      }
    } else if (errors) {
      setErrors(errors);
    } else {
      console.log("EMMAILL", values.email);
      setEmail(values.email);
      props.onFinish();
    }
  },
})(C);
