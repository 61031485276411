import store from "../stores/store";
import { getHistoricalData } from "./pricedata";

export function setAcceptTerms(value: boolean) {
  store.acceptTerms = value;
}

export function setReferrerXtag(value: string) {
  store.referrerXtag = value;
}

export function setDisplayCurrency(value: string) {
  store.displayCurrency = value;
}

export function setEmail(value: string) {
  console.log("useMLL: ", value);
  store.user.email = value;
}

export function setPercentageChange24hr(value: number) {
  store.percentageChange24hr = value;
}

export function setPriceChange24hr(value: number) {
  store.priceChange24hr = value;
}

export function setPrice(value: any) {
  store.price = value;
}

export function setSatsValue(value: any) {
  store.satsValue = value;
}

export function setHistoricalPrices(prices: { price: any; timestamp: any }[]) {
  // @ts-ignore
  store.historicalPrices = prices;
}

export async function refreshHistoricalPrices(series: string) {
  let prices = await getHistoricalData(series);
  setHistoricalPrices(prices);
}

export function setSeriesDays(series: string) {
  store.seriesDays = series;
}

export function wipeStore() {
  // address verification flow
  store.addrVer.account = "";
  store.addrVer.address = "";
  store.addrVer.sig = "";
  store.addrVer.sigId = "";
  store.addrVer.aoppUrl = "";

  // app data
  store.manualSigning = false;

  // user data
  store.user.id = "";
  store.user.payRef = "";
  store.user.xtag = "";
  store.user.email = "";

  // historical data
  store.historicalPrices = [];
  store.seriesDays = "182";

  // temp
  // registration
  store.acceptTerms = false;
  store.referrerXtag = "";
  store.acceptAffiliateTerms = false;

  // orders store
  store.orders = [];
  store.firstOrderDate = "";
  store.displayCurrency = "EUR";
  store.priceChange24hr = 0;
  store.percentageChange24hr = 0;
  store.price = 0;
  store.satsValue = 0;

  // stacking metrics
  store.lastMonthSatsStacked = 0;
  store.lastMonthPaymentsTotal = 0;
  store.totalSatsStacked = 0;
  store.paymentsTotal = 0;
  store.totalAffiliatesDiscount = 0;
  store.totalProfitLossCHF = 0;
}
