import * as React from "react";
import { Form as AntForm, Button } from "antd";
import { Form, FormikProps, Formik, FormikHelpers } from "formik";
import {
  WithAoppCheckAddressSig,
  withAoppCheckAddressSig,
  WithInitiateAopp,
  withInitiateAopp,
} from "@xsats-m/controller";
import { observer } from "mobx-react-lite";
import store from "../../../../stores/store";
import Swal from "sweetalert2";
import { setAoppUrl } from "../../../../action/addrVer";
import { validAccountSchema } from "@xsats-m/common";
import ProgressBarView from "../ProgressBarView";
import { Link } from "react-router-dom";
var QRCode = require("qrcode.react");
// import progressIcon from "../../../assets/images/onboarding-icons/onboarding-flow-progress-1@0.5x.png";

const FormItem = AntForm.Item;

interface FormValues {
  account: string;
}

interface CheckSigValues {
  account: string;
  sigId: string;
}

// either get passed back formik error | null
interface Props {
  nextStep: () => void;
  prevStep: () => void;
  goToStep: (step: number) => void;
  values: { account: string };
  submit: (values: FormValues) => Promise<string | null>;
  step: number;
}

// Formik takes FormValues then creates some types which are passed into the props => can do * below with nice autocompletion
// The '&' merges the two types/interfaces
const C = observer(
  (
    props: Props &
      FormikProps<FormValues> &
      WithInitiateAopp &
      WithAoppCheckAddressSig
  ) => {
    const back = (e: { preventDefault: () => void }) => {
      e.preventDefault();
      props.prevStep();
    };

    const submit = async (
      values: FormValues | CheckSigValues,
      { setSubmitting }: FormikHelpers<FormValues>
    ) => {
      // addAddress() is function exported from AddAddress in Controller
      try {
        let response;

        if (store.addrVer.aoppUrl) {
          let sigId = store.addrVer.aoppUrl.substring(
            store.addrVer.aoppUrl.indexOf("/verify/") + 8
          );
          values = {
            account: store.addrVer.account,
            sigId: sigId,
          };
          response = await props.aoppCheckAddressSig(values);
        } else {
          response = await props.aoppInitiateRequestMutation(values);
        }

        console.log(response);
        if (!response && !store.addrVer.aoppUrl) {
          // problem - shouldn't happen
          console.log("NO RESPONSE");
          await Swal.fire({
            icon: "error",
            title: "Oops...",
            text: store.addrVer.aoppUrl
              ? "Failed to verify signature"
              : "Failed to retrieve URL",
          });
          return;
        }

        // success
        if (
          typeof response === "string" &&
          response.substring(0, 4) === "aopp"
        ) {
          setAoppUrl(response);
          return;
        }

        // @ts-ignore
        if (response && response.aopp) {
          await Swal.fire({
            icon: "error",
            // @ts-ignore
            title: JSON.stringify(response.aopp, null, 2),
            text: "Please try again or contact support if the problem persists",
          });
        }

        if (!response && store.addrVer.aoppUrl) {
          // successfully signed
          await Swal.fire({
            icon: "success",
            title: "Signature Verified",
            text: "You're ready to stack some sats!",
          });
          setTimeout(() => {
            props.goToStep(4);
          }, 2000);
        }
      } catch (err) {
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: JSON.stringify(err, null, 2),
        });
      }
      setSubmitting(false);
    };

    return (
      <Formik
        initialValues={{
          account: store.addrVer.account,
        }}
        validationSchema={validAccountSchema}
        onSubmit={submit}
      >
        {({ isSubmitting, isValid }) => (
          <Form style={{ display: "flex", marginTop: 100 }}>
            <div className="Form" style={{ width: 800, margin: "auto" }}>
              <ProgressBarView step={props.step} />
              <h1 className="Header">
                <strong>Sign with your wallet</strong>.
              </h1>
              <h2 className="Subtext">
                Retrieve an address and signed message from an AOPP compatible
                wallet.
                {/* (e.g.{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://sparrowwallet.com/"
                >
                  Sparrow
                </a>{" "}
                or
                <Link to="/blue"> BlueSign</Link>) */}
              </h2>

              {store.addrVer.aoppUrl ? (
                <div>
                  <div className="Columns">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={store.addrVer.aoppUrl}
                    >
                      <QRCode value={store.addrVer.aoppUrl} size={300} />
                    </a>
                    <div className="Column">
                      <h2 className="List">
                        <strong>1) Scan or click QR</strong>
                      </h2>
                      <h2 className="List">
                        <strong>2) Select account (+address)</strong>
                      </h2>
                      <h2 className="List">
                        <strong>3) Tap "Sign and submit"</strong>
                      </h2>
                      <h2 className="List">
                        <strong>4) Return here</strong>
                      </h2>
                    </div>
                  </div>
                  <p className="Description" style={{ marginTop: 30 }}>
                    <strong>
                      Ensure that the following details match those in the
                      message you sign:
                    </strong>
                    <br />
                    <strong>Account #:</strong> {store.addrVer.account} <br />{" "}
                    <strong>Signature Id:</strong>{" "}
                    {store.addrVer.aoppUrl.substring(
                      store.addrVer.aoppUrl.indexOf("/verify/") + 8
                    )}
                  </p>
                  <div>
                    <FormItem>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="FormButton"
                      >
                        I SIGNED THE MESSAGE
                      </Button>
                    </FormItem>
                  </div>
                </div>
              ) : (
                <div>
                  <FormItem>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="FormButton"
                    >
                      PROCEED
                    </Button>
                  </FormItem>
                </div>
              )}
              <div>
                <Button className="BackButton" color="secondary" onClick={back}>
                  Back
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
);

export const AOPPSigView = withAoppCheckAddressSig(withInitiateAopp(C));
