import React from 'react';

// components
import Sidebar from "../components/dashboard/Sidebar";
import Navbar from "../components/dashboard/Navbar";
import Preloader from "../components/dashboard/Preloader";
import FooterRaw from "../modules/shared/Footer/FooterRaw";
import Header from "../components/layout/Header";
import { observer } from "mobx-react-lite";

import {isMobile} from 'react-device-detect';

export const SidebarLayout = observer(({ children }) => {
    const [loaded, setLoaded] = React.useState(false);
  
    React.useEffect(() => {
      const timer = setTimeout(() => setLoaded(true), 1000);
      return () => clearTimeout(timer);
    }, []);
  
    // const localStorageIsSettingsVisible = () => {
    //   return localStorage.getItem('settingsVisible') === 'false' ? false : true
    // }
  
    // const [showSettings, setShowSettings] = React.useState(localStorageIsSettingsVisible);
  
    // const toggleSettings = () => {
    //   setShowSettings(!showSettings);
    //   localStorage.setItem('settingsVisible', !showSettings);
    // }
  
    return (
        <>
          <Preloader show={loaded ? false : true} />
          <Sidebar />
          <main className="content ct-major-tenth" style={{width: isMobile ? "100%" : "85%"}}>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              {/* <Navbar /> */}
              <Header navPosition="right" className="reveal-from-bottom" hideSignin={true} showLogout={true} style={{marginBottom: 50}}  />
            </div>
              {children}
            <FooterRaw />
          </main>
        </>
    );
  });