import store from "../stores/store";
import CoinGecko from "coingecko-api";

export function updatePrice(currency: string, price: number) {
  store.websocket[currency + "BTCPrice"] = price;
}

export function updateSatsValue(currency: string, value: number) {
  store.websocket[currency + "satsValue"] = ~~value;
}

const CoinGeckoClient = new CoinGecko();

// function gets historical btc price data
export async function getHistoricalData(series: string) {
  // get historical data for bitcoin based on series length
  const { data } = await CoinGeckoClient.coins.fetchMarketChart("bitcoin", {
    days: series,
    vs_currency: store.displayCurrency,
  });

  // format the data, and calculate necessary totals
  let prices: { price: any; timestamp: any }[] = [];
  data.prices.forEach((price: any[]) =>
    prices.push({ price: price[1], timestamp: price[0] })
  );

  return prices;
}
