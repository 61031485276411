import * as React from "react";
import { LogoutController } from "@xsats-m/controller";
import { RouteComponentProps } from "react-router-dom";

import { CallLogout } from "./CallLogout";
import { wipeStore } from "../../action/user";

export class Logout extends React.PureComponent<RouteComponentProps<{}>> {
  onFinish = () => {
    wipeStore();
    this.props.history.push("/restart");
  };
  render() {
    return (
      <LogoutController>
        {({ logout }) => (
          <CallLogout
            logout={logout}
            onFinish={this.onFinish}
            history={this.props.history}
            location={this.props.location}
            match={this.props.match}
          />
        )}
      </LogoutController>
    );
  }
}
