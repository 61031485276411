import * as React from "react";
import { Form as AntForm, Button } from "antd";
import { BankOutlined } from "@ant-design/icons";
import { withFormik, FormikProps, Field, Form } from "formik";
import { validAccountSchema } from "@xsats-m/common";
import { InputField } from "../../../shared/InputField";
import {
  clearAddrVerStore,
  clearSigStore,
  setAccount,
} from "../../../../action/addrVer";

import { observer } from "mobx-react-lite";
import store from "../../../../stores/store";
import ProgressBarView from "../ProgressBarView";

const FormItem = AntForm.Item;

interface FormValues {
  account: string;
}

// either get passed back formik error | null
interface Props {
  nextStep: () => void;
  handleChange: (input: string) => (e: { target: { value: any } }) => void;
  values: { account: string; address: string; sig: string };
  step: number;
}

const C = observer((props: Props & FormikProps<FormValues>) => {
  React.useEffect(() => {
    clearAddrVerStore();
    clearSigStore();
  }, []);

  const { values, handleChange, handleSubmit, step } = props;

  return (
    <Form style={{ display: "flex", marginTop: 100 }}>
      <div className="Form" style={{ width: 800, margin: "auto" }}>
        <ProgressBarView step={step} />
        <h1 className="Header">
          <strong>Enter IBAN or Account Number</strong>.
        </h1>
        <h2 className="Subtext">
          Enter the IBAN associated with your bank account, or your UK account
          number.
        </h2>
        <p className="Description">
          Please make sure that you provide the account that you will use to buy
          bitcoin.
        </p>
        <Field
          name="account"
          prefix={(<BankOutlined style={{ color: "#FF5E00" }} />) as any}
          placeholder="IBAN or Account number..."
          onChange={handleChange("account")}
          defaultValue={store.addrVer.account || values.account}
          component={InputField}
        />
        <FormItem>
          <Button
            onClick={() => handleSubmit}
            type="primary"
            htmlType="submit"
            className="FormButton"
          >
            NEXT
          </Button>
        </FormItem>
      </div>
    </Form>
  );
});

export const AccountNumberInputView = withFormik<Props, FormValues>({
  validationSchema: validAccountSchema,
  mapPropsToValues: () => ({
    account: "",
  }),
  handleSubmit: async (values, { props }) => {
    setAccount(values.account);
    props.nextStep();
  },
})(C);
