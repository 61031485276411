import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';
import xsatsLogo from '../../../assets/images/xsats-logo-tagline-save-and-secure-bitcoin.png'

const Logo = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'brand',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <h1 className="m-0">
        {/* <Link to="https://xsats.com"> */}
        <a href="https://xsats.com" target="_blank" rel = "noopener noreferrer">
          <Image
            src={xsatsLogo}
            alt="Open"
            width={140}/>
        </a>
        {/* </Link> */}
      </h1>
    </div>
  );
}

export default Logo;