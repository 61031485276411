import * as React from "react";
import { LoginController } from "@xsats-m/controller";

import { LoginView } from "./view/LoginView";
import { RouteComponentProps } from "react-router-dom";

// container -> view
// container -> connector -> view       "have a container, which feeds a connector, which decides which view is selected"
// view is different for react/react-native, but container is shared
// controller -> connector -> view
// controller contains the submit function, which it passes to connector which in turn passes it to View

interface FormValues {
  email: string;
  password: string;
  totp?: string;
}

export class LoginConnector extends React.PureComponent<RouteComponentProps> {
  onFinish = () => {
    const {
      history,
      location: { state },
    } = this.props;
    // Redirect to specified next page. If state.next exists => redirect to there
    // Better UX for users that visit auth page, are asked for login creds -> auto redirect back to same auth page
    // @ts-ignore
    if (state && state.next) {
      // @ts-ignore
      return history.push(state.next);
    }
    // history.push("/addresses");
    history.push("/buy");
    // history.push("/home");
  };

  totpInputRedirect = (values: FormValues) => {
    this.props.history.push({ pathname: "/restart", state: values });
  };

  render() {
    console.log(this.props.location.state);
    return (
      <LoginController>
        {({ submit }) => (
          <LoginView
            onFinish={this.onFinish}
            submit={submit}
            totpInput={this.totpInputRedirect}
            location={this.props.location}
            history={this.props.history}
            match={this.props.match}
          />
        )}
      </LoginController>
    );
  }
}
