import {
  convertMoneyStringToFloat,
  momentConvertTime,
  roundNumber,
} from "@xsats-m/common";
import moment from "moment";
import store from "../stores/store";

interface Order {
  bitcoin_withdrawal_amount_sats: number;
  base_currency_exchange_rate: number;
  base_currency: string;
  order_size: string;
  startDateTimestamp?: number;
  created: string;
  complete: boolean;
  settled_time: string;
  referral_discount_sats: number;
}

const now = moment.utc(new Date()).format("YYYY-MM-DDTHH:mm:ss.sssZ");

export function saveOrders(orders: never[]) {
  // retrieve order data via GraphQL query
  store.orders = orders;
}

function getBTCExchangeRate(currency: string) {
  return store.websocket[currency + "BTCPrice"];
}

export function calculateFiatProfitLoss(
  totalPayments: number,
  totalSatsStacked: number,
  currency: string = "CHF"
) {
  let profit = roundNumber(
    (totalSatsStacked / 10 ** 8) * getBTCExchangeRate(currency) - totalPayments,
    2
  );

  return profit;
}

export function refreshStackingMetrics(
  totalPayments: number,
  totalSatsStacked: number
) {
  let profit = calculateFiatProfitLoss(totalPayments, totalSatsStacked);
  setFiatProfitLoss(profit);
}

export function setFiatProfitLoss(profit: number, currency: string = "CHF") {
  store.totalProfitLossCHF = profit;
}

export function loadMonthlyTotalDiffs(
  orders: Order[],
  displayCurrency: string = "CHF"
) {
  const { totalSats, totalPayments } = sumCompletedOrderTotalsForPeriod(
    orders,
    5
  );

  store.lastMonthSatsStacked = totalSats;
  store.lastMonthPaymentsTotal = totalPayments;
}

export function saveOrderTotals(
  orders: Order[],
  displayCurrency: string = "CHF"
) {
  const { totalPayments, totalSats } = sumCompletedOrderTotalsForPeriod(orders);

  console.log("TOTAL PAYMENTS", totalPayments);
  console.log("SATS TOTAL: ", totalSats);
  store.paymentsTotal = totalPayments;
  store.totalSatsStacked = totalSats;
  store.displayCurrency = displayCurrency;

  refreshStackingMetrics(totalPayments, totalSats);

  loadMonthlyTotalDiffs(orders);

  return totalSats;
}

/**
 * Returns total order amount in sats received (totalSats)
 * and payments sent (paymentsTotal),
 * and the amount saved from being a referral (totalAffiliatesDiscount).
 * If no period provided, returns all time total.
 */
export function sumCompletedOrderTotalsForPeriod(
  orders: Order[],
  daysSinceToday?: number
) {
  let ordersSats: number[] = [];
  let ordersPayments: number[] = [];
  let orderAffiliateDiscountsSats: number[] = [];

  let ordersInPeriod = daysSinceToday
    ? orders.filter((order) => {
        console.log(moment(now).diff(order.created, "days"));
        return moment(now).diff(order.created, "days") > daysSinceToday;
      })
    : orders;

  console.log("ORDERS: ");
  console.log(ordersInPeriod);

  ordersInPeriod.forEach((order: Order) => {
    let sats = order.bitcoin_withdrawal_amount_sats;

    console.log(order.bitcoin_withdrawal_amount_sats);

    ordersSats.push(sats);
    orderAffiliateDiscountsSats.push(order.referral_discount_sats);

    let amount =
      convertMoneyStringToFloat(order.order_size) *
      order.base_currency_exchange_rate;

    console.log("ORDER AMOUNT: ", amount);
    ordersPayments.push(amount);
  });

  let totalSats = ordersSats.reduce((a: any, b: any) => a + b, 0);
  let totalPayments = ordersPayments.reduce((a: any, b: any) => a + b, 0);
  let totalAffiliatesDiscount = orderAffiliateDiscountsSats.reduce(
    (a: any, b: any) => a + b,
    0
  );

  return {
    totalSats,
    totalPayments,
    totalAffiliatesDiscount,
  };
}

export function saveFirstOrderDate(orders: Order[]) {
  let date = orders[orders.length - 1];
  console.log(date.created);
  store.firstOrderDate = momentConvertTime(date.created);
}
