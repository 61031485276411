import * as React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
// import { AuthRoute, LiteAuthRoute } from "@xsats-m/controller";

import { LoginConnector } from "../modules/login/LoginConnector";
import { ForgotPasswordConnector } from "../modules/forgotPassword/ForgotPasswordConnector";
import { ChangePasswordConnector } from "../modules/changePassword/ChangePasswordConnector";
import { MessagePage } from "../modules/messagePage";
import { Logout } from "../modules/logout";
import { StrippedRegisterConnector } from "../modules/register/StrippedRegisterConnector";
// import { AddAddressConnector } from "../modules/address/add/AddAddressConnector";
// import { FindAddressesConnector } from "../modules/address/find/FindAddressesConnector";
// import { VerifyIdentityView } from "../modules/verification/VerifyIdentityView";
import VerifySigForm from "../modules/address/verifySig/VerifySigForm";
import { BankDetailsView } from "../modules/payment/BankDetailsView";
import { ConfirmationView } from "../modules/payment/ConfirmationView";
import AppRoute from "../utils/AppRoute";
import LayoutAuth from "../components/layout/LayoutAuth";
import LayoutDefault from "../components/layout/LayoutDefault";
import LiteAppAuthRoute from "../utils/LiteAppAuthRoute";
import BlueRedirectComponent from "../utils/BlueLink";
import Orders from "../pages/Orders";
import { SidebarLayout } from "../utils/SidebarLayout";
import { observer } from "mobx-react-lite";
import store from "../stores/store";

export const Routes = observer(() => {
  React.useEffect(() => {
    // @ts-ignore
    window.$crisp = [];
    // @ts-ignore
    window.CRISP_WEBSITE_ID = "873476f7-570f-4a71-b0dd-bbca54137212";

    // @ts-ignore
    window.$crisp.push(["set", "user:email", [store.user.email]]);
    // @ts-ignore
    window.$crisp.push(["set", "user:nickname", [store.user.xtag]]);

    (function () {
      var d = document;
      var s = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      // @ts-ignore
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          {/* <Route exact={true} path="/start" component={RegisterConnector} /> */}
          <Route exact path="/start">
            <Redirect to="/x/" />
          </Route>
          <Route exact path="/">
            <Redirect to="/restart" />
          </Route>
          <AppRoute
            exact={false}
            path="/x"
            component={StrippedRegisterConnector}
            layout={LayoutDefault}
          />
          <AppRoute
            exact={true}
            path="/restart"
            component={LoginConnector}
            layout={LayoutDefault}
          />
          <AppRoute
            exact={true}
            path="/forgot-password"
            component={ForgotPasswordConnector}
            layout={LayoutDefault}
          />
          <AppRoute
            exact={true}
            path="/cpw/:key"
            component={ChangePasswordConnector}
            layout={LayoutDefault}
          />
          <AppRoute path="/m" component={MessagePage} layout={LayoutDefault} />
          <Route path="/exit" component={Logout} />
          <Route exact path="/blue" component={BlueRedirectComponent} />
          {/* <AppRoute
          exact={true}
          path="/u/details"
          component={RegisterDetailsConnector}
          layout={LayoutDefault}
        />
        <AppRoute
          exact={true}
          path="/u/addr"
          component={RecordResidencyConnector}
          layout={LayoutDefault}
        />
        <AppRoute
          exact={true}
          path="/u/cdd"
          component={VerifyIdentityView}
          layout={LayoutDefault}
        /> */}
          <LiteAppAuthRoute
            exact={false}
            path="/buy"
            component={VerifySigForm}
            layout={LayoutAuth}
          />
          {/* <LiteAppAuthRoute
          path="/addresses"
          component={FindAddressesConnector}
          layout={LayoutAuth}
        /> */}
          <Route
            exact={true}
            path="/m/reset-password"
            component={MessagePage}
          />
          {/* <LayoutAuth>
          <AuthRoute path="/add-address" component={AddAddressConnector} />
        </LayoutAuth> */}
          <LiteAppAuthRoute
            exact={true}
            path="/pay"
            component={BankDetailsView}
            layout={LayoutAuth}
          />
          <LiteAppAuthRoute
            exact={true}
            path="/confirmed"
            component={ConfirmationView}
            layout={LayoutAuth}
          />
          {/* <LiteAppAuthRoute
            exact={true}
            path="/home"
            component={DashboardOverview}
            layout={SidebarLayout}
          /> */}
          <LiteAppAuthRoute
            exact={true}
            path="/Orders"
            component={Orders}
            layout={SidebarLayout}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
});
