import * as React from "react";
import { Button } from "antd";
import bitcoinLifecycle from "../../assets/images/bitcoin-transaction-lifecycle-xsats-order.png";
import { Link } from "react-router-dom";
import ProgressBarView from "../address/verifySig/ProgressBarView";

interface Props {
  nextStep: () => void;
  prevStep: () => void;
}

export const ConfirmationView = (props: Props) => {
  const back = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    props.prevStep();
  };
  return (
    <div style={{ display: "flex", paddingTop: 100 }}>
      <div
        className="Form"
        style={{
          width: 800,
          margin: "auto",
        }}
      >
        <ProgressBarView step={5} />
        <h1 className="Header">
          <strong>What happens next?</strong>
        </h1>
        <img
          src={bitcoinLifecycle}
          alt="bitcoin tx lifecycle"
          style={{ width: 600 }}
        />
        {/* <Link to={"/addresses"}>
          <Button className="FormButton" type="primary">
            VIEW ADDRESSES
          </Button>
        </Link> */}
        <Link to={"/pay"}>
          <Button className="BackButton" color="secondary">
            Back
          </Button>
        </Link>
      </div>
    </div>
  );
};

let styles = {
  titleElement: {
    backgroundColor: "black",
    paddingTop: 10,
    paddingHorizontal: 30,
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  titleText: {
    fontSize: 14,
    fontWeight: "500",
    color: "#FF5E00",
    fontFamily: "ubuntu-bold",
  },
  attributeElement: {
    paddingTop: 0,
    paddingBottom: 3,
    paddingHorizontal: 40,
  },
  attributeText: {
    fontSize: 22,
    fontWeight: "300",
    color: "white",
    fontFamily: "ubuntu-regular",
  },
  attributeTextWithIcon: {
    fontSize: 22,
    fontWeight: "500",
    color: "#fff",
    width: "100%",
  },
  profileContent: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    alignItems: "center",
    alignSelf: "stretch",
    width: "100%",
  },
  button3: {
    borderColor: "#FF5E00",
    borderRadius: 20,
    borderWidth: 2,
    backgroundColor: "#FF5E00", //"#CCCCCC",
    marginHorizontal: 40,
    marginVertical: 10,
    paddingVertical: 10,
    alignItems: "center",
  },
};
