import * as React from "react";
import { Query, QueryResult } from "react-apollo";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { Button, Table } from "antd";
import Swal from "sweetalert2";

import {
  retrieveBankDetailsQuery,
  RetrieveBankDetailsQuery_retrieveBankDetails,
  WithRetrieveBankDetails,
} from "@xsats-m/controller";
import { formatNumber } from "@xsats-m/common";

import ProgressBarView from "../address/verifySig/ProgressBarView";

import store from "../../stores/store";
import * as user from "../../action/user";
import { clearAddrVerStore } from "../../action/addrVer";

export const currencies = [
  {
    label: "€",
    value: "EUR",
  },
  {
    label: "₣",
    value: "CHF",
  },
];

interface Props {
  nextStep: () => void;
}

export const BankDetailsView = observer(
  (props: WithRetrieveBankDetails & Props) => {
    let activeCurrency = store.displayCurrency;

    // TODO: generalise when adding new currencies
    let activeCurrencySymbol: string = activeCurrency === "CHF" ? "₣" : "€";

    React.useEffect(() => {
      clearAddrVerStore();
    }, []);

    // TODO: extract fee from server config instead of hard coding
    // only euros and chf for now
    const formatUniversalData = (
      bankData: RetrieveBankDetailsQuery_retrieveBankDetails
    ) => {
      console.log(bankData);
      // TODO - implement switch currency button
      const currency = store.displayCurrency; // "EUR";
      const paymentData = [
        {
          label: "PAYMENT REFERENCE",
          detail:
            store.addrVer.sigId !== ""
              ? store.addrVer.sigId
              : bankData.reference,
        },
        {
          label: "VOLUME LIMITS",
          detail: `${activeCurrencySymbol}${
            bankData["daily" + currency + "Limit"]
          }         per 24HRS \n${activeCurrencySymbol}${formatNumber(
            bankData["monthly" + currency + "Limit"]!
          )}   per MONTH \n${activeCurrencySymbol}${
            bankData.minPayment
          }              MINIMUM`,
        },
        {
          label: "FEE",
          detail: `1.35% \n(+0.50¢ for orders under ${activeCurrencySymbol}100)`,
        },
      ];

      const companyData = [
        {
          label: "ACCOUNT NAME",
          detail: bankData[currency + "accountName"],
        },
        {
          label: "ACCOUNT ADDRESS",
          detail: bankData[currency + "accountAddress"],
        },
        {
          label: "IBAN",
          detail: bankData[currency + "iban"],
        },
        {
          label: "BIC/SWIFT CODE",
          detail: bankData[currency + "bic"],
        },
      ];

      const bankerData = [
        {
          label: "BANK NAME",
          detail: bankData[currency + "bankName"],
        },
        {
          label: "BANK ADDRESS",
          detail: bankData[currency + "bankAddress"],
        },
      ];

      return { paymentData, companyData, bankerData };
    };

    const columns = [
      {
        dataIndex: "label",
        key: "label",
      },
      {
        dataIndex: "detail",
        key: "detail",
      },
    ];

    const PaymentTable = ({ data }: any) => {
      return (
        <Table
          style={{ whiteSpace: "pre" }}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                navigator.clipboard.writeText(record.detail!.toString());
                Swal.fire({
                  position: "top-end",
                  icon: "success",
                  title: `Copied ${record.label}`,
                  showConfirmButton: false,
                  timer: 1000,
                  backdrop: false,
                });
              }, // click row
            };
          }}
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      );
    };

    return (
      <div style={{ display: "flex", paddingTop: 100 }}>
        <div
          className="Form"
          style={{
            width: 800,
            margin: "auto",
          }}
        >
          <ProgressBarView step={4} />
          <h1 className="Header">
            <strong>Send payment</strong>.
          </h1>
          <h2 className="Subtext">
            Purchase bitcoin using the account details below.
          </h2>
          <p className="Description">
            Set up a standing order to automatically accumulate bitcoin savings,
            buy the dips or FOMO buy at your convenience. <br />
            <br /> Before making a payment, please make sure you have entered
            the details correctly. <br />
          </p>
          <div className="currency-actions">
            {currencies.map((currency) => (
              <button
                key={currency.value}
                className={`${
                  currency.value === activeCurrency ? "active" : "inactive"
                }`}
                onClick={() => user.setDisplayCurrency(currency.value)}
              >
                {currency.label}
              </button>
            ))}
          </div>
          <div>
            <Query query={retrieveBankDetailsQuery}>
              {({ data, loading }: QueryResult) => {
                if (loading || typeof data === "undefined") return null;

                let bankData: RetrieveBankDetailsQuery_retrieveBankDetails =
                  data.retrieveBankDetails;

                let universalData = formatUniversalData(bankData);

                return (
                  <div style={{ flexDirection: "row", marginBottom: 60 }}>
                    <PaymentTable data={universalData.paymentData} />
                    <PaymentTable data={universalData.companyData} />
                    <PaymentTable data={universalData.bankerData} />
                    <div
                      style={{
                        alignItems: "center",
                        marginTop: 20,
                        textAlign: "justify",
                      }}
                    >
                      <div>
                        <p>
                          IN ORDER TO AVOID UNNECESSARY DELAYS AND/OR ADDITIONAL
                          FEES PLEASE CHECK AND DOUBLE CHECK THAT YOUR PAYMENT
                          DETAILS EXACTLY MATCH THE INFORMATION ABOVE BEFORE
                          SENDING
                        </p>
                      </div>
                    </div>
                    <h2
                      className="Description"
                      style={{
                        fontWeight: "700",
                        textAlign: "center",
                        marginTop: 20,
                      }}
                    >
                      Please note: currently we only accept EUR and CHF.
                    </h2>
                    <h2
                      className="Description"
                      style={{ fontWeight: "700", textAlign: "center" }}
                    >
                      Be sure to use the appropriate currency account to avoid
                      unnecessary delays and bank fees.
                    </h2>
                    <h2
                      style={{
                        fontWeight: "900",
                        textAlign: "center",
                        marginTop: 60,
                      }}
                    >
                      TIP
                    </h2>
                    <h2
                      className="Description"
                      style={{
                        fontWeight: "700",
                        // textAlign: "center",
                        marginTop: 20,
                      }}
                    >
                      Use your personal code ({bankData.reference}) as payment
                      reference to automatically cycle through receive addresses
                      in the order you configured in the mobile app (defaults to
                      order of submission).
                    </h2>
                    <h2
                      className="Description"
                      style={{
                        fontWeight: "700",
                        // textAlign: "center",
                        marginTop: 20,
                      }}
                    >
                      Alternatively, use the sig ID ({bankData.sig_id}) to
                      receive to the specific address/wallet you just added.
                    </h2>
                  </div>
                );
              }}
            </Query>
            <Link to={"/confirmed"}>
              <Button className="FormButton" type="primary">
                DONE
              </Button>
            </Link>
            <Link to={"/buy"}>
              <Button className="FormButton" type="primary">
                QUEUE ANOTHER ADDRESS
              </Button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
);
// TODO: insert t's and c's

let styles = {
  titleElement: {
    backgroundColor: "black",
    paddingTop: 10,
    paddingHorizontal: 30,
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  titleText: {
    fontSize: 14,
    fontWeight: "500",
    color: "#FF5E00",
    fontFamily: "ubuntu-bold",
  },
  attributeElement: {
    paddingTop: 0,
    paddingBottom: 3,
    paddingHorizontal: 40,
  },
  attributeText: {
    fontSize: 22,
    fontWeight: "300",
    color: "white",
    fontFamily: "ubuntu-regular",
  },
  attributeTextWithIcon: {
    fontSize: 22,
    fontWeight: "500",
    color: "#fff",
    width: "100%",
  },
  profileContent: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    alignItems: "center",
    alignSelf: "stretch",
    width: "100%",
  },
  button3: {
    borderColor: "#FF5E00",
    borderRadius: 20,
    borderWidth: 2,
    backgroundColor: "#FF5E00", //"#CCCCCC",
    marginHorizontal: 40,
    marginVertical: 10,
    paddingVertical: 10,
    alignItems: "center",
  },
  linkText: { color: "#FF5E00" },
};
