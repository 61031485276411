import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faCloudUploadAlt, faPlus, faRocket, faTasks, faUserShield, faCheck, faCog, faHome, faSearch, faArrowCircleDown  } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from '@themesberg/react-bootstrap';

import { OrdersTable } from "../components/dashboard/Tables";

let COUNTDOWN_DURATION = 20;

export default () => {
  const refreshTableRef = React.useRef(null);
  const [counter, setCounter] = React.useState(COUNTDOWN_DURATION);
  const [intervalId, setIntervalId] = React.useState(null);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);


  function timer () {
    if (counter > 0) {
      setTimeout( () => {
        setButtonDisabled(false);
      }, COUNTDOWN_DURATION) //this will enable button after 30 seconds you can change time here.
    
      setInterval(() => setCounter(counter => counter - 1), 1000);
    } else {
        setCounter(COUNTDOWN_DURATION);
        setButtonDisabled(false);
        setIntervalId(null);
    }
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item ><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>xsats</Breadcrumb.Item>
            <Breadcrumb.Item active className="text-secondary">Orders</Breadcrumb.Item>
          </Breadcrumb>
          <h4 className="text-white" >Orders</h4>
          <p className="mb-0 text-white">Your purchase history.</p>
        </div>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Dropdown className="btn-toolbar">
          <Dropdown.Toggle as={Button} variant="primary" size="sm" className="me-2">
            <FontAwesomeIcon icon={faPlus} className="me-2" />New Task
          </Dropdown.Toggle>
          <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
            {/* <Dropdown.Item className="fw-bold">
              <FontAwesomeIcon icon={faTasks} className="me-2" /> New Task
            </Dropdown.Item> */}
            <Dropdown.Item className="fw-bold">
              <FontAwesomeIcon icon={faCloudUploadAlt} className="me-2" /> Upload Files
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold">
              <FontAwesomeIcon icon={faUserShield} className="me-2" /> Security
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item className="fw-bold">
              <FontAwesomeIcon icon={faRocket} color={"#FF5E00"} className="text-danger me-2" /> Buy Bitcoin
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      </div>

      {/* <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" />
            </InputGroup>
          </Col>
        </Row>
        zqxv b90m.;/

      </div> */}
       <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-white" size="sm">Download</Button>
            <Button variant="outline-white" size="sm">Export</Button>
          </ButtonGroup>
        </div>
        <div className="btn-toolbar ">
        {/* <Dropdown.Toggle as={Button} variant="primary" size="sm" className="me-2">
            <FontAwesomeIcon icon={faPlus} className="me-2" />New Task
          </Dropdown.Toggle> */}
          <ButtonGroup>
            <Button variant="" size="sm" disabled={counter !== COUNTDOWN_DURATION && counter > 0} onClick={()=> {
              setCounter(counter-1)
              refreshTableRef.current.refreshTable();
              timer();
              }}>{counter !== COUNTDOWN_DURATION && counter > 0 ? <p>{counter}s</p> : null}<FontAwesomeIcon icon={faArrowCircleDown} className="me-2" />Refresh</Button>
          </ButtonGroup>
        </div>
         <div>
          {/* <Col xs={4} md={2} xl={1} className="ps-md-0 text-start">
            <Dropdown as={ButtonGroup} style={{ marginRight: "60"}} >
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0" >
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} color={"white"} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10 <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col> */}
        </div>
        </div>
      <OrdersTable ref={refreshTableRef} />
    </>
  );
};