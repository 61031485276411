import * as React from "react";
import { Form as AntForm, Button } from "antd";
import { withFormik, FormikProps, Field, Form } from "formik";
import { InputField } from "../../shared/InputField";
import { Link } from "react-router-dom";
import { NormalisedErrorMap } from "@xsats-m/controller";
import { MailOutlined } from "@ant-design/icons";
import { forgotPasswordSchema } from "@xsats-m/common";

const FormItem = AntForm.Item;

interface FormValues {
  email: string;
}

// either get passed back formik error | null
interface Props {
  onFinish: () => void;
  submit: (values: FormValues) => Promise<NormalisedErrorMap | null>;
}

// Formik takes FormValues then creates some types which are passed into the props => can do * below with nice autocompletion
// The '&' merges the two types/interfaces
class C extends React.PureComponent<FormikProps<FormValues> & Props> {
  render() {
    return (
      <Form style={{ display: "flex", marginTop: 100 }}>
        <div className="FormEntry" style={{ width: 500, margin: "auto" }}>
          <h1 style={{ fontSize: 28, fontFamily: "ubuntu" }}>
            <strong>Password Reset.</strong>
          </h1>
          <h3
            className="Subtext"
            style={{ marginBottom: 10, fontSize: 16, color: "#000" }}
          >
            Enter your email address to receive a link to reset your password.
          </h3>
          <Field
            name="email"
            prefix={
              (
                <MailOutlined
                  type="mail"
                  style={{ color: "rgba(0,0,0,.25)" }}
                />
              ) as any
            }
            placeholder="Email"
            component={InputField}
          />
          <FormItem>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{
                width: "100%",
                backgroundColor: "#FF5E00",
                borderWidth: 0,
              }}
            >
              Reset Password
            </Button>
          </FormItem>
          <FormItem>
            Found it?
            <Link to="/restart">
              <strong>Continue Stacking</strong>
            </Link>
          </FormItem>
        </div>
      </Form>
    );
  }
}

export const ForgotPasswordView = withFormik<Props, FormValues>({
  validationSchema: forgotPasswordSchema,
  mapPropsToValues: () => ({
    email: "",
  }),
  handleSubmit: async (values, { props, setErrors }) => {
    const errors = await props.submit(values);
    if (errors) {
      setErrors(errors);
    } else {
      props.onFinish();
    }
  },
})(C);
