import React from "react";
import { RiBankLine } from "react-icons/ri";
import { FaSignature, FaRegCheckCircle } from "react-icons/fa";
import { BiTransferAlt } from "react-icons/bi";
import { BitcoinCircleIcon } from "@bitcoin-design/bitcoin-icons-react/filled";

import "react-step-progress-bar/styles.css";
// @ts-ignore
import { ProgressBar, Step } from "react-step-progress-bar";

// either get passed back formik error | null
interface Props {
  step: number;
}

class ProgressBarView extends React.Component<Props> {
  render() {
    const { step } = this.props;

    return (
      <div className="buy-progress-bar">
        <ProgressBar
          percent={((~~step - 1) / 4) * 100}
          filledBackground="linear-gradient(to right, #FF9800, #FF5E00)"
        >
          <Step>
            {({ accomplished, index }) => (
              <div
                className={`indexedStep ${accomplished ? "accomplished" : ""}`}
              >
                <div className="progress-stage">
                  <div className="progress-icon-container">
                    <RiBankLine
                      className="progress-icon"
                      style={{ color: accomplished ? "#FF5E00" : "#ccc" }}
                      size="40px"
                    />
                  </div>
                  <p className="buy-progress-bar-label">Provide IBAN</p>
                </div>
              </div>
            )}
          </Step>
          <Step>
            {({ accomplished, index }) => (
              <div
                className={`indexedStep ${accomplished ? "accomplished" : ""}`}
              >
                <div className="progress-stage">
                  <div className="progress-icon-container">
                    <BitcoinCircleIcon
                      className="progress-icon"
                      style={{
                        height: "50px",
                        width: "50px",
                        color: accomplished ? "#FF5E00" : "#ccc",
                      }}
                    />
                  </div>
                  <p className="buy-progress-bar-label">
                    Enter bitcoin address
                  </p>
                </div>
              </div>
            )}
          </Step>
          <Step>
            {({ accomplished, index }) => (
              <div
                className={`indexedStep ${accomplished ? "accomplished" : ""}`}
              >
                <div className="progress-stage">
                  <div className="progress-icon-container">
                    <FaSignature
                      className="progress-icon"
                      style={{ color: accomplished ? "#FF5E00" : "#ccc" }}
                      size="40px"
                    />
                  </div>
                  <p className="buy-progress-bar-label">Verify Address</p>
                </div>
              </div>
            )}
          </Step>
          <Step>
            {({ accomplished, index }) => (
              <div
                className={`indexedStep ${accomplished ? "accomplished" : ""}`}
              >
                <div className="progress-stage">
                  <div className="progress-icon-container">
                    <BiTransferAlt
                      className="progress-icon"
                      style={{ color: accomplished ? "#FF5E00" : "#ccc" }}
                      size="40px"
                    />
                  </div>
                  <p className="buy-progress-bar-label">Send payment</p>
                </div>
              </div>
            )}
          </Step>
          <Step>
            {({ accomplished, index }) => (
              <div
                className={`indexedStep ${accomplished ? "accomplished" : ""}`}
              >
                <div className="progress-stage">
                  <div className="progress-icon-container">
                    <FaRegCheckCircle
                      className="progress-icon"
                      style={{ color: accomplished ? "#00FF00" : "#ccc" }}
                      size="40px"
                    />
                  </div>
                  <p className="buy-progress-bar-label">Receive sats</p>
                </div>
              </div>
            )}
          </Step>
        </ProgressBar>
      </div>
    );
  }
}

export default ProgressBarView;
