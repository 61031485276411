import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";

const host =
  process.env.NODE_ENV === "production"
    ? "https://do.xsats.com:443"
    : "http://localhost:4000";

export const client = new ApolloClient({
  link: new HttpLink({
    uri: host,
    credentials: "include",
  }),
  cache: new InMemoryCache(),
});
