import * as React from "react";
import { withFormik, Field, Form, FormikProps } from "formik";
import { v4 } from "uuid";

import { validAddressSchema } from "@xsats-m/common";

import { Form as AntForm, Button } from "antd";
import { InputField } from "../../../shared/InputField";
import bitcoinIcon from "../../../../assets/images/bitcoin-icon.png";
import { setAddress, setManualSigning } from "../../../../action/addrVer";
import store from "../../../../stores/store";
import { observer } from "mobx-react-lite";
import ProgressBarView from "../ProgressBarView";

import * as addrVer from "../../../../action/addrVer";

const FormItem = AntForm.Item;

interface FormValues {
  address: string;
}

// either get passed back formik error | null
interface Props {
  nextStep: () => void;
  prevStep: () => void;
  goToStep: (step: number) => void;
  handleChange: (input: any) => (e: { target: { value: any } }) => void;
  values: { account: string; address: string; sig: string };
  step: number;
}

// Formik takes FormValues then creates some types which are passed into the props => can do * below with nice autocompletion
// The '&' merges the two types/interfaces
const C = observer((props: Props & FormikProps<FormValues>) => {
  const addressRef = React.useRef(null);

  const back = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    props.prevStep();
  };

  const goToStep = (e: { preventDefault: () => void }, step: number) => {
    e.preventDefault();
    props.goToStep(step);
  };

  const { values, handleChange, handleSubmit, step } = props;

  return (
    <Form style={{ display: "flex", marginTop: 100 }}>
      <div className="Form" style={{ width: 800, margin: "auto" }}>
        <ProgressBarView step={step} />
        <h1 className="Header">
          <strong>Enter address</strong>.
        </h1>
        <h2 className="Subtext">
          Enter your Bitcoin wallet address. <br />
        </h2>
        <p className="Description">
          This tells us where to send your bitcoin. <br /> Note that{" "}
          <strong> you alone must own and control the wallet</strong>.<br />
        </p>
        {store.manualSigning === true ? (
          <Field
            name="address"
            prefix={
              (
                <img
                  src={bitcoinIcon}
                  alt={""}
                  color="#FF5E00"
                  style={{
                    color: "#FF5E00",
                    width: 14,
                    height: 20,
                  }}
                />
              ) as any
            }
            placeholder="Bitcoin address..."
            onChange={handleChange("address")}
            defaultValue={store.addrVer.address || values.address}
            component={InputField}
          />
        ) : (
          <p className="Description">
            How would you like to select and verify your address?
          </p>
        )}
        <div className="Columns">
          <FormItem style={{ width: "100%", marginRight: 10 }}>
            <Button
              onClick={() =>
                store.manualSigning ? handleSubmit : setManualSigning(true)
              }
              type="primary"
              htmlType="submit"
              className="FormButton"
            >
              {store.manualSigning ? "PROCEED" : "MANUALLY"}
            </Button>
          </FormItem>
          {store.manualSigning === false ? (
            <Button
              style={{ marginLeft: 10 }}
              className="FormButton"
              type="primary"
              onClick={(e) => {
                goToStep(e, 3.1);
              }}
            >
              AUTOMATICALLY
            </Button>
          ) : null}
        </div>
        <div>
          <Button
            className="BackButton"
            color="secondary"
            onClick={(e) =>
              store.manualSigning ? setManualSigning(false) : back(e)
            }
          >
            Back
          </Button>
        </div>
      </div>
    </Form>
  );
});

export const AddressInputView = withFormik<Props, FormValues>({
  validationSchema: validAddressSchema,
  mapPropsToValues: () => ({
    address: "",
  }),
  handleSubmit: async (values, { props }) => {
    setAddress(values.address);
    // generate sigId (id associated with a particular address/sig - can be used multiple times so not strictly an orderId)
    let sig_id = v4().toString().replace(/-/g, "").toUpperCase().slice(-16);
    addrVer.setSigId(sig_id);
    props.nextStep();
  },
})(C);
