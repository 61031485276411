import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import Swal from "sweetalert2";

interface Props {
  logout: () => void;
  onFinish: () => void;
}

export class CallLogout extends React.PureComponent<
  Props & RouteComponentProps<{}>
> {
  async componentDidMount() {
    Swal.fire({
      icon: "question",
      title: "Exit Order?",
      text: "Please confirm to abandon this order.",
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.props.logout();
        this.props.onFinish();
      } else {
        this.props.history.push("/home");
      }
    });
  }
  render() {
    return null;
  }
}
