import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "../../assets/lotties/36195-send-confirmation.json";

export class MessagePage extends React.PureComponent<RouteComponentProps<{}>> {
  render() {
    // console.log(this.props);
    // const {
    //   location: { state }
    // } = this.props;
    // const mes = this.props.state.message
    //return <h2>{state && state.message ? state.message : "ERR"}</h2>;
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <div>
        <div
          style={{ display: "flex", marginTop: 100, justifyContent: "center" }}
        >
          <h1 style={{ color: "#FFF" }} className="Header">
            Confirm your email via the link sent to your inbox.
          </h1>
        </div>
        <Lottie options={defaultOptions} height={400} width={400} />
      </div>
    );
  }
}
