import store from "../stores/store";

export function setAccount(account: string) {
  store.addrVer.account = account;
}

export function setAddress(address: string) {
  store.addrVer.address = address;
}

export function setSig(sig: string) {
  store.addrVer.sig = sig;
}

export function setSigId(sigId: string) {
  store.addrVer.sigId = sigId;
}

export function setUserId(userId: string) {
  store.user.id = userId;
}

export function setAoppUrl(url: string) {
  store.addrVer.aoppUrl = url;
}

export function setManualSigning(value: boolean) {
  store.manualSigning = value;
}

export function clearSigStore() {
  store.addrVer.sig = "";
  store.addrVer.sigId = "";
}

export function clearAddrVerStore() {
  store.addrVer.aoppUrl = "";
  store.addrVer.address = "";
  store.addrVer.account = "";
  store.addrVer.sig = "";
  store.manualSigning = false;
}
