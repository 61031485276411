import * as React from 'react';
import {isIOS, isAndroid} from 'react-device-detect';

  export default class BlueRedirectComponent extends React.Component {

    render() {
        (function() {
            var app = {
              launchApp: function() {
                if (isIOS) {
                    window.location.replace("bluesign://");
                } else if (isAndroid) {
                    window.location.replace("market://details?gotohome=com.xsats.bluesign");
                }
                this.timer = setTimeout(this.openWebApp, 1000);
              },
          
              openWebApp: function() {
                if (isIOS) {
                    window.location.replace("https://apps.apple.com/app/bluesign-bitcoin-wallet/id1611910489");
                } else if (isAndroid) {
                    window.location.replace("https://play.google.com/store/apps/details?id=com.xsats.bluesign");
                } else {
                    window.location.replace("https://apps.apple.com/app/bluesign-bitcoin-wallet/id1611910489");
                }
              }
            };
          
            app.launchApp();
          })();
      return (
        null
      )
    }
  }