import * as React from "react";
import { Form as AntForm, Button, Checkbox } from "antd";
import { withFormik, FormikProps, Field, Form, ErrorMessage } from "formik";
import { validStrippedUserSchema } from "@xsats-m/common";
import { Link, RouteComponentProps } from "react-router-dom";
import { findXtagQuery, NormalisedErrorMap } from "@xsats-m/controller";
import { Query, QueryResult } from "react-apollo";

import Image from "../../../components/elements/Image";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { InputField } from "../../shared/InputField";
import Swal from "sweetalert2";

import xsatsIcon from "../../../assets/images/xsats-logo-orange-circle@0.5x.png";
// @ts-ignore
import terms from "../../../assets/documents/xsats-AG-Terms-and-Conditions.pdf";

import store from "../../../stores/store";
import * as user from "../../../action/user";
import { observer } from "mobx-react-lite";

const FormItem = AntForm.Item;

interface FormValues {
  email: string;
  password: string;
  acceptTerms: boolean;
  referralXtag?: string;
}

// either get passed back formik error | null
interface Props {
  onFinish: () => void;
  submit: (values: FormValues) => Promise<NormalisedErrorMap | null>;
}

// Formik takes FormValues then creates some types which are passed into the props => can do * below with nice autocompletion
// The '&' merges the two types/interfaces
const C = observer((props: Props & FormikProps<FormValues>) => {
  React.useEffect(() => {
    // @ts-ignore
    const xsatsTag = props.location.pathname.split("/x/").pop();
    // TODO: query existing registered referal participants and present accordingly
    if (xsatsTag) {
      user.setReferrerXtag(xsatsTag);
    }
    // this.props.setFieldValue("referralXtag", this.state.xsatsTag);
  }, [props]);

  return (
    <Form style={{ display: "flex", marginTop: 100 }}>
      <Query query={findXtagQuery} variables={{ xtag: store.referrerXtag }}>
        {({ data, loading }: QueryResult) => {
          // essential to allow access to properties of object
          if (loading || typeof data === "undefined" || data.errors)
            return null;

          const { initialValues } = props;

          let isMatch = data.findXtag.match;
          // @ts-ignore
          let xsatsTag = props.location.pathname.split("/x/").pop();
          if (
            !isMatch &&
            xsatsTag &&
            xsatsTag !== "" &&
            xsatsTag !== "/x/" &&
            xsatsTag !== "/x"
          ) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Invalid referral link.",
            }).then(() => {
              setTimeout(() => {
                user.setReferrerXtag("");
              }, 1000);
              // @ts-ignore
              props.history.push("/x/");
            });
          } else if (isMatch && store.referrerXtag) {
            // this.props.setFieldValue("referralXtag", this.state.xsatsTag);
            initialValues.referralXtag = store.referrerXtag;
          }

          let showAffiliate =
            store.referrerXtag &&
            store.referrerXtag !== "/x" &&
            isMatch === true
              ? true
              : false;

          return (
            <div
              className="FormEntry"
              style={{
                width: showAffiliate === true ? 800 : 500,
                margin: "auto",
              }}
            >
              <div>
                <h1 style={{ fontSize: 28, fontFamily: "ubuntu" }}>
                  <strong>Buy bitcoin.</strong>
                </h1>
                <h2 className="Subtext" style={{ marginBottom: 10 }}>
                  Start saving today!
                </h2>
              </div>
              <Field
                name="email"
                prefix={
                  (
                    <MailOutlined type="mail" style={{ color: "#FF5E00" }} />
                  ) as any
                }
                placeholder="Email"
                component={InputField}
              />
              <Field
                name="password"
                type="password"
                prefix={
                  (
                    <LockOutlined type="lock" style={{ color: "#FF5E00" }} />
                  ) as any
                }
                placeholder="Password"
                component={InputField}
              />
              <Checkbox
                name="acceptTerms"
                className="Checkbox"
                checked={store.acceptTerms}
                onChange={() => {
                  let acceptTermsValue = !store.acceptTerms;
                  user.setAcceptTerms(acceptTermsValue);
                  props.setFieldValue("acceptTerms", acceptTermsValue);
                }}
              >
                Accept{" "}
                <a
                  target="_blank"
                  className="linkInverted"
                  href={terms}
                  rel="noreferrer"
                >
                  Terms and Conditions
                </a>
              </Checkbox>
              <ErrorMessage
                name="acceptTerms"
                component="div"
                className="invalid-feedback"
              />
              {/* <Field name="referralXtag" hidden /> */}
              <FormItem>
                <Button type="primary" htmlType="submit" className="FormButton">
                  BUY BITCOIN
                </Button>
              </FormItem>
              <FormItem className="FormText">
                Used xsats before?{" "}
                <Link to="/restart" className="FormTextBold">
                  <strong>Buy again.</strong>
                </Link>
              </FormItem>
            </div>
          );
        }}
      </Query>
    </Form>
  );
});

export const StrippedRegisterView = withFormik<
  RouteComponentProps & Props,
  FormValues
>({
  validationSchema: validStrippedUserSchema,
  mapPropsToValues: () => ({
    email: "",
    password: "",
    acceptTerms: false,
    referralXtag: "",
  }),
  handleSubmit: async (values, { props, setErrors }) => {
    const errors = await props.submit(values);
    if (errors) {
      setErrors(errors);
    } else {
      props.onFinish();
    }
  },
})(C);
